import { setCurrentPage, setMembers, setTotalPages } from '.'
import {
  ADD_MEMBERS_ROUTE,
  API_URL,
  MEMBERS_ROUTE,
  REMOVE_MEMBER_ROUTE,
  REMOVE_GRACE_PERIOD_ROUTE,
  UNBLOCK_MEMBERS_ROUTE,
} from '../../strings/api-consts'
import { SelectOptions } from '../../types/DropDownTypes'
import {
  AddMemberResponse,
  GracePeriod,
  MemberConditionalAccessState,
  MemberPayload,
  Members,
} from '../../types/MemberTypes'
import { CustomThunkDispatch } from '../../types/UserTypes'
import * as Sentry from '@sentry/react'

export const getMembers =
  (
    numberOfDevicesFilter?: SelectOptions[],
    nameFilter?: string,
    enrolledDevicesFilter?: SelectOptions[],
    page?: number,
    policiesFilter?: string
  ) =>
  async (dispatch: CustomThunkDispatch) => {
    let url = API_URL + MEMBERS_ROUTE + `?page=${page ? page : 1}&pageSize=${40}`
    if (enrolledDevicesFilter) {
      if (enrolledDevicesFilter.length === 2 || enrolledDevicesFilter.length === 0) {
        url += ''
      } else if (enrolledDevicesFilter.length === 1 && enrolledDevicesFilter[0].value === 'no') {
        url += '&enrolled=false'
      } else {
        url += '&enrolled=true'
      }
    }

    if (nameFilter && nameFilter !== '') {
      const encodedNameFilter = encodeURIComponent(nameFilter)
      url += `&name=${encodedNameFilter}`
    }

    if (policiesFilter && policiesFilter !== MemberConditionalAccessState.ALL) {
      url += `&policy=${policiesFilter}`
    }

    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((members: Members) => {
        if (numberOfDevicesFilter && numberOfDevicesFilter?.length !== 0) {
          const filteredMembers = numberOfDevicesFilter!
            .map((filterValue) => {
              return members.members.filter((member) => member.devices.length === parseInt(filterValue.value))
            })
            .flat()
          dispatch(setMembers(filteredMembers))
        } else {
          dispatch(setCurrentPage(!page ? 0 : page - 1))
          dispatch(setTotalPages(members.totalPages))
          dispatch(setMembers(members.members))
        }
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
        return false
      })
  }

export const addMembers = (membersPayload: MemberPayload[]) => async () => {
  const url = API_URL + ADD_MEMBERS_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(membersPayload),
  })
    .then((res) => res.json())
    .then((response: AddMemberResponse) => {
      return response
    })
    .catch((err) => {
      return err
    })
}

export const postUnblockMember = (memberId: string, memberBlockedByPolicy: string, gracePeriod: string) => async () => {
  const url = API_URL + UNBLOCK_MEMBERS_ROUTE

  const now = new Date()
  let unblockTime: Date

  switch (gracePeriod) {
    case GracePeriod.ONE_HOUR:
      unblockTime = new Date(now.getTime() + 1 * 60 * 60 * 1000) // 1 hour
      break
    case GracePeriod.FOUR_HOURS:
      unblockTime = new Date(now.getTime() + 4 * 60 * 60 * 1000) // 4 hours
      break
    case GracePeriod.ONE_DAY:
      unblockTime = new Date(now.getTime() + 24 * 60 * 60 * 1000) // 1 day
      break
    case GracePeriod.THREE_DAYS:
      unblockTime = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000) // 3 days
      break
    case GracePeriod.ONE_WEEK:
      unblockTime = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000) // 1 week
      break
    default:
      unblockTime = now
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify({ memberId: memberId, policy: memberBlockedByPolicy, gracePeriod: unblockTime.toISOString() }),
  })
    .then((res) => {
      if (res.status === 500) {
        throw new Error('Server error')
      }
      return true
    })
    .catch((error) => {
      Sentry.captureMessage(error.message)
      return undefined
    })
}

export const postRemoveMemberGracePeriod = (memberId: string) => async () => {
  const url = API_URL + REMOVE_GRACE_PERIOD_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify({ memberId: memberId }),
  })
    .then((res) => {
      if (res.status === 500) {
        throw new Error('Server error')
      }
      return true
    })
    .catch((error) => {
      Sentry.captureMessage(error.message)
      return undefined
    })
}

export const postRemoveMember = (memberId: string) => async () => {
  const url = API_URL + REMOVE_MEMBER_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify({ memberId: memberId }),
  })
    .then((res) => {
      if (res.status === 500) {
        throw new Error('Server error')
      }
      return true
    })
    .catch((error) => {
      Sentry.captureMessage(error.message)
      return undefined
    })
}
