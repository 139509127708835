import { updatePassword } from '../../reducers/users'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { useNavigate, useParams } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { adminPasswordSchema } from '../../schemas/LoginSchemas'
import toast from 'react-hot-toast'
import loginBackground from '../../assets/login_background.svg'
import LogoGradientIcon from '../../icons/LogoGradientIcon'
import { Button } from '@radix-ui/themes'
import { Input } from '../../stories/input/Input'

type setPasswordInputs = {
  password: string
  confirmPassword: string
}

function AdminPassword() {
  const [password, setPassword] = useState<string>('')
  const [passwordCheck, setPasswordCheck] = useState<string>('')
  const [passwordsValid, setPasswordsValid] = useState<boolean>(false)

  const di = useAppDispatch()
  const navigate = useNavigate()
  const { token } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<setPasswordInputs>({ resolver: yupResolver(adminPasswordSchema) })

  const onSubmit: SubmitHandler<setPasswordInputs> = async (data) => {
    const updatedPassword = await di(updatePassword({ password: data.password, requestToken: token! }))
    if (updatedPassword.error) {
      toast.error(updatedPassword.error, { duration: 6000 })
    } else {
      toast.success('Your password has been set successfully', { duration: 6000 })
      navigate('/login')
    }
  }

  const conditions = [
    { regex: /.{8,}/, label: '8 characters,' },
    { regex: /[a-z]/, label: 'one lowercase,' },
    { regex: /[A-Z]/, label: 'one uppercase,' },
    { regex: /[0-9]/, label: 'one digit,' },
    { regex: /[^A-Za-z0-9]/, label: 'one special character' },
  ]

  const checkPassword = (regex: RegExp) => {
    return password.match(regex) ? 'text-green-500' : 'text-ErrorRed'
  }

  useEffect(() => {
    setPasswordsValid(areAllConditionsMet() && passwordCheck === password)
  }, [password, passwordCheck])

  const areAllConditionsMet = () => {
    return conditions.every((condition) => condition.regex.test(password))
  }

  return (
    <div className="flex light h-screen bg-neutral-2">
      <div className="absolute top-6 left-6">
        <LogoGradientIcon height="26" width="71" />
      </div>
      <div className="flex flex-col md:flex-row w-full">
        <div className="w-full my-52 w-1/2 lg:my-0 flex items-center justify-center">
          <form onSubmit={handleSubmit(onSubmit)} className="w-[80%] lg:w-[70%] 2xl:w-[60%] max-w-[460px]">
            <p className="text-black text-3xl text-left mb-6">Reset Password</p>
            <label className="text-black w-full">Password</label>
            <Input
              type="password"
              placeholder="password"
              {...register('password', { required: true })}
              onChange={setPassword}
            />
            {errors.password && <p className="text-ErrorRed">{errors.password.message}</p>}
            <p className="text-neutral-11 mb-6">
              At least
              {conditions.map((condition, index) => (
                <span key={index} className={checkPassword(condition.regex)}>
                  {' '}
                  {condition.label}
                </span>
              ))}
            </p>
            <label className="text-black w-full">Confirm Password</label>
            <Input
              type="password"
              placeholder="confirm password"
              {...register('confirmPassword', { required: true })}
              onChange={setPasswordCheck}
              error={errors.confirmPassword ? true : false}
            />
            {errors.confirmPassword && <p className="text-ErrorRed">{errors.confirmPassword.message}</p>}
            <input
              type="submit"
              className={`w-full bg-PurpleBlue-9 hover:bg-PurpleBlue-10 active:bg-PurpleBlue-10 ${!passwordsValid ? 'opacity-50' : ''} rounded mt-5 p-2.5 text-white font-semibold text-sm cursor-pointer`}
              value="Update Password"
              disabled={!passwordsValid}
            />
            <Button
              onClick={() => navigate('/login')}
              className="text-center mt-6 cursor-pointer ml-0 w-[96%]"
              variant="ghost"
            >
              Back to Sign In
            </Button>
          </form>
        </div>
        <div className="hidden lg:flex w-1/2 items-start justify-start pr-8 py-8 relative mt-0">
          <div className="absolute bottom-8 p-8 pr-16">
            <p className="text-white text-2xl lg:text-3xl font-medium">
              A Privacy-Conscious Platform
              <br />
              for Advanced Threats
            </p>
            <p className="text-white text-lg lg:text-xl">
              Stay ahead of zero-day attacks, threats,
              <br />
              and vulnerabilities
            </p>
          </div>
          <img
            src={loginBackground}
            alt="Login page background"
            className="w-full h-full object-cover rounded-[32px]"
          />
        </div>
      </div>
    </div>
  )
}

export default AdminPassword
