import { Skeleton } from '@radix-ui/themes'

const PageSkeleton = () => {
  return (
    <div className="w-full flex flex-col h-screen p-4">
      {/* Row 1 */}
      <div className="flex w-full space-x-4 h-12 mb-2 justify-between">
        <div className="flex w-1/2 space-x-4">
          <Skeleton className="h-12 w-full flex-grow" />
          <Skeleton className="h-12 w-full flex-grow" />
          <Skeleton className="h-12 w-full flex-grow" />
        </div>
        <div className="flex w-1/2 space-x-4">
          <Skeleton className="h-12 w-1/3 ml-auto" />
        </div>
      </div>
      {/* Row 2 */}
      <div className="flex space-x-4 flex-grow">
        <Skeleton className="w-full h-[85%] flex-grow" />
      </div>
    </div>
  )
}

export default PageSkeleton
