/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Activity } from '../../types/ActivityTypes'

export interface ActivityState {
  scans: Activity[] | undefined
  totalPages: number
  currentPage: number
}

const initialState: ActivityState = {
  scans: undefined,
  totalPages: 1,
  currentPage: 0,
}

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setScans(state, action: PayloadAction<any>) {
      state.scans = action.payload
    },
    setTotalPages(state, action: PayloadAction<any>) {
      state.totalPages = action.payload
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload
    },
  },
})
export const selectActivity = (state: RootState) => state.activity

export const { setScans, setTotalPages, setCurrentPage } = activitySlice.actions
export default activitySlice.reducer
