import { IconHistory, IconUser } from '@tabler/icons-react'

export const settingsUserNavItems = [
  {
    type: 'ADMIN',
    display: 'Admin',
    icon: IconUser
  },
  {
    type: 'AUDIT',
    display: 'Audit Log',
    icon: IconHistory,
  }
]

export const settingsLoginNavItems = [
  {
    type: 'MDM',
    display: 'MDM',
  },
  {
    type: 'SCIM',
    display: 'SCIM',
  },
  {
    type: 'SSO',
    display: 'SSO',
  },
  {
    type: 'INTEGRATIONS',
    display: 'Integrations',
  },
]

export const settingsAppNavItems = [
  {
    type: 'NOTIFICATION',
    display: 'Notifications',
  },
]

export type NavItems = {
  type: string
  display: string
}

export const settingsLogoutNavItems = [
  {
    type: 'SIGN_OUT',
    display: 'Sign Out',
  },
]
