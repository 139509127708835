import * as yup from 'yup'
import { EMAIL_REGEX } from '../strings/yup-regex'

export const loginSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email address').required('Email is a required field'),
  password: yup.string().required('Password is a required field'),
})

export const forgotSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email address').required('Email is a required field'),
})

export const adminPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is a required field')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&€^+£;:~#{}<>(),.|'"\[\]\{\}\-\_\/])[A-Za-z\d@$!%*?&€^+£;:~#{}<>(),.|'"'"\[\]\{\}\-\_\/]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match')
    .required('Confirm password is a required field'),
})
