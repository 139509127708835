/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import {
  deactivateWebhook,
  deleteConditionalAccess,
  generateAPIKey,
  getAPIKey,
  getConditionalAccessConfig,
  getConditionalAccessPolicy,
  getWebhook,
  getWebhookSecret,
  postConditionalAccessConfig,
  postDisableConditionalAccess,
  revokeAPIKey,
  saveWebhook,
  selectSettings,
  setConditionalAccess,
  setConditionalAccessPolicy,
} from '../../reducers/settings'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Flex, IconButton, Separator, Switch, Text } from '@radix-ui/themes'
import Select from 'react-select'
import { CopyIcon, EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import { formatForTimestampWithDate } from '../../utils/dates'
import IntegrationModal from './IntegrationModal'
import toast from 'react-hot-toast'
import { ConditionalAccessResponse, WebhookResponse } from '../../types/SettingsType'
import ConditionAccessOptions from './ConditionAccessOptions'
import { Input } from '../../stories/input/Input'

function IntegrationSettings() {
  const { apiKey, webhookSecret, webhook, conditionalAccess } = useSelector(selectSettings)
  const [showKey, setShowKey] = useState<boolean>(false)
  const [showWebhookKey, setShowWebhookKey] = useState<boolean>(false)
  const [showWebhook, setShowWebhook] = useState<boolean>(false)
  const [showConditionalAccess, setShowConditionalAccess] = useState<boolean>(false)
  const [showRevokeModal, setShowRevokeModal] = useState<boolean>(false)
  const [screenToShow, setScreenToShow] = useState<string>('REVOKE')
  const [expiry, setExpiry] = useState({ value: 'ONE_YEAR', label: '1 Year' })
  const [webhookURL, setWebhookURL] = useState<string>('')
  const [webhookValidating, setWebhookValidating] = useState<boolean>(false)
  const di = useAppDispatch()
  const dispatch = useDispatch()

  const options = [
    { value: 'SIX_MONTHS', label: '6 Months' },
    { value: 'ONE_YEAR', label: '1 Year' },
    { value: 'NEVER', label: 'Never' },
  ]

  useEffect(() => {
    di(getAPIKey())
    fetchWebhookData()
    fetchConditionalAccessData()
  }, [])

  const revokeKey = () => {
    di(revokeAPIKey())
    toast.success('API Key successfully revoked', { duration: 6000 })
  }

  const generateKey = () => {
    if (apiKey) {
      setScreenToShow('REGENERATE')
      setShowRevokeModal(true)
    } else {
      di(generateAPIKey(expiry.value))
      toast.success('API Key successfully generated', { duration: 6000 })
    }
  }

  const regenerateKey = () => {
    di(generateAPIKey(expiry.value))
    toast.success('API Key successfully regenerated', { duration: 6000 })
  }

  const saveWebhookDetails = async () => {
    setWebhookValidating(true)
    const response: WebhookResponse = await di(saveWebhook({ secret: webhookSecret!.secret, url: webhookURL }))
    if (!response.success) {
      toast.error(response.message, { duration: 6000 })
    } else {
      toast.success('Webhook successfully saved', { duration: 6000 })
    }
    setWebhookValidating(false)
  }

  const fetchWebhookData = async () => {
    const webhook = await di(getWebhook())
    if (!webhook) {
      await di(getWebhookSecret())
    } else {
      setWebhookURL(webhook.url)
      setShowWebhook(true)
    }
  }

  const fetchConditionalAccessData = async () => {
    const conditionalAccess = await di(getConditionalAccessConfig())
    const conditionalAccessPolicy = await di(getConditionalAccessPolicy())
    if (conditionalAccess) {
      dispatch(setConditionalAccess(conditionalAccess))
      conditionalAccess.active ? setShowConditionalAccess(true) : undefined
    }
    if (conditionalAccessPolicy) {
      dispatch(setConditionalAccessPolicy(conditionalAccessPolicy))
    }
  }

  const toggleWebhook = (e: boolean) => {
    if (!e && webhook) {
      setShowWebhook(!showWebhook)
      setScreenToShow('WEBHOOK')
      setShowRevokeModal(true)
    } else {
      setShowWebhook(!showWebhook)
    }
  }

  const toggleConditionalAccess = (e: boolean) => {
    setShowConditionalAccess(!showConditionalAccess)
    if (!e && conditionalAccess) {
      setScreenToShow('CONDITIONAL_ACCESS_DISABLE')
      setShowRevokeModal(true)
    } else {
      setConditionalAccessConfigActive()
      setShowConditionalAccess(!showConditionalAccess)
    }
  }

  const callDeactivateWebhook = async () => {
    await di(deactivateWebhook())
    setWebhookURL('')
    toast.success('Webhook successfully deactivated', { duration: 6000 })
  }

  const removeConditionalAccess = async () => {
    await di(deleteConditionalAccess())
    const policy = await di(getConditionalAccessPolicy())
    dispatch(setConditionalAccess(undefined))
    dispatch(setConditionalAccessPolicy(policy))
    setShowConditionalAccess(false)
    toast.success('Conditional Access successfully removed', { duration: 6000 })
  }

  const disableConditionalAccess = async () => {
    const inactiveBody: ConditionalAccessResponse = {
      ...conditionalAccess!,
      active: false,
    }
    const disabled = await di(postDisableConditionalAccess(inactiveBody))
    dispatch(setConditionalAccess(inactiveBody))
    setShowConditionalAccess(false)

    disabled
      ? toast.success('Conditional Access successfully disabled', { duration: 6000 })
      : toast.error('Unable to disable Conditional Access. Please try again', { duration: 6000 })
  }

  const cancelDeactivateWebhook = () => {
    setShowWebhook(!showWebhook)
  }

  const cancelRemoveConditionalAccess = () => {
    setShowConditionalAccess(!showConditionalAccess)
  }

  const setConditionalAccessConfigActive = async () => {
    if (conditionalAccess) {
      const activeBody: ConditionalAccessResponse = {
        ...conditionalAccess,
        active: true,
      }
      const successfulSave = await di(postConditionalAccessConfig(activeBody))
      if (successfulSave) {
        const policy = await di(getConditionalAccessPolicy())
        if (policy) {
          dispatch(setConditionalAccessPolicy(policy))
          toast.success('Conditional Access Active', { duration: 6000 })
        }
      }
    }
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: '38px',
      minHeight: '38px',
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: '30px',
      minHeight: '30px',
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    placeholder: (base: any) => ({
      ...base,
      height: '30px',
      minHeight: '30px',
      paddingTop: '0px',
    }),
    input: (base: any) => ({
      ...base,
      height: '36px',
      minHeight: '36px',
      marginTop: '0px',
      marginBottom: '0px',
      paddingTop: '0px',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  }

  return (
    <>
      <div className="w-full h-[calc(100vh_-_80px)] overflow-auto">
        {/* API Key settings */}
        <Flex direction="column" gap="3" maxWidth="100%" className="z-40">
          <Card variant="surface">
            <Text as="div" size="2" weight="bold">
              API Settings
            </Text>
            <Separator my="3" size="4" />
            {apiKey && (
              <div>
                <Text as="div" size="1" className="mt-2" color="gray">
                  API Key
                </Text>
                <div className="w-full flex justify-between space-x-4">
                  <div className="h-[38px] flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm w-2/3">
                    {showKey ? apiKey.token : '*********************'}
                    <div className="flex">
                      <IconButton size="2" variant="ghost" className="mr-1" onClick={() => setShowKey(!showKey)}>
                        {showKey ? <EyeNoneIcon height="16" width="16" /> : <EyeOpenIcon height="16" width="16" />}
                      </IconButton>
                      <IconButton
                        size="2"
                        variant="ghost"
                        onClick={() => {
                          navigator.clipboard.writeText(apiKey.token)
                          toast.success('API Key copied to clipboard', { duration: 6000 })
                        }}
                      >
                        <CopyIcon height="16" width="16" />
                      </IconButton>
                    </div>
                  </div>
                  <Button
                    variant="solid"
                    className="h-[38px] w-1/3 bg-ErrorRed cursor-pointer mt-1 float-right"
                    onClick={() => {
                      setScreenToShow('REVOKE')
                      setShowRevokeModal(true)
                    }}
                  >
                    Revoke API Key
                  </Button>
                </div>
                <Text as="div" size="1" className="mt-1 " color="gray">
                  {apiKey.expiresAt
                    ? 'Expires on ' + formatForTimestampWithDate(apiKey.expiresAt)
                    : 'API Key set to not expire'}
                </Text>
              </div>
            )}
            <Text as="div" size="1" className="mt-2" color="gray">
              Expiry
            </Text>
            <div className="flex mt-1">
              <Select
                defaultValue={options.find((option) => option.value === 'ONE_YEAR')}
                onChange={(e: any) => setExpiry(e)}
                options={options}
                className={'w-full mr-4 z-50'}
                menuPortalTarget={document.body}
                styles={{ ...customStyles }}
              />
              <Button
                variant="solid"
                className="h-[38px] w-1/3 bg-PurpleBlue-10 text-white cursor-pointer"
                onClick={() => generateKey()}
              >
                {apiKey ? 'Re-g' : 'G'}enerate API Key
              </Button>
            </div>
            {expiry.value !== 'ONE_YEAR' && (
              <Text as="div" color="gray" size="2" className="mt-4">
                Consider choosing 1-year token to rotate credentials periodically
              </Text>
            )}
          </Card>
        </Flex>

        {/* Webhook settings */}
        <Flex direction="column" gap="3" maxWidth="100%" className="mt-8">
          <Card variant="surface">
            <Flex justify="between">
              <Text as="div" size="2" weight="bold">
                Webhook Settings
              </Text>
              <Switch size="2" checked={showWebhook} onCheckedChange={(e) => toggleWebhook(e)} />
            </Flex>
            {showWebhook && (
              <>
                <Separator my="3" size="4" />
                <div className="flex mt-2 justify-between">
                  <div className="w-full mr-4">
                    <Text as="div" size="1" className="mt-2" color="gray">
                      Webhook Secret
                    </Text>
                    <div className="h-[42px] flex mt-1 border-InputBorderGrey border rounded px-2 py-2.5 justify-between text-sm">
                      {showWebhookKey ? webhookSecret?.secret : '*********************'}
                      <div className="flex mt-0.5">
                        <IconButton
                          size="2"
                          variant="ghost"
                          className="mr-1"
                          onClick={() => setShowWebhookKey(!showWebhookKey)}
                        >
                          {showWebhookKey ? (
                            <EyeNoneIcon height="16" width="16" />
                          ) : (
                            <EyeOpenIcon height="16" width="16" />
                          )}
                        </IconButton>
                        <IconButton
                          size="2"
                          variant="ghost"
                          onClick={() => {
                            navigator.clipboard.writeText(webhookSecret!.secret)
                            toast.success('API Key copied to clipboard', { duration: 6000 })
                          }}
                        >
                          <CopyIcon height="16" width="16" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mr-4">
                    <Text as="div" size="1" className="mt-2 mb-1" color="gray">
                      Webhook URL
                    </Text>
                    <Input type="text" value={webhookURL} onChange={setWebhookURL} />
                  </div>
                  <Button
                    variant="solid"
                    size="3"
                    className={`h-9 w-[200px] bg-PurpleBlue-10 text-white cursor-pointer text-sm font-semibold mt-7`}
                    onClick={() => saveWebhookDetails()}
                    disabled={webhookValidating}
                  >
                    {webhookValidating ? 'Validating...' : 'Save'}
                  </Button>
                </div>
                <Text as="div" color="gray" size="2" className="mt-4">
                  Read{' '}
                  <span className="text-PurpleBlue-10 cursor-pointer">
                    <a href="https://docs.iverify.io/iverify-webhooks" target="_blank">
                      Webhooks Setup Documentation
                    </a>
                  </span>
                </Text>
              </>
            )}
          </Card>
        </Flex>

        {/* CAC settings */}
        <Flex direction="column" gap="3" maxWidth="100%" className="mt-8">
          <Card variant="surface">
            <Flex justify="between">
              <Text as="div" size="2" weight="bold">
                Conditional Access (Beta)
              </Text>
              <Switch size="2" checked={showConditionalAccess} onCheckedChange={(e) => toggleConditionalAccess(e)} />
            </Flex>
            {showConditionalAccess && (
              <ConditionAccessOptions setScreenToShow={setScreenToShow} setShowRevokeModal={setShowRevokeModal} />
            )}
          </Card>
        </Flex>
      </div>
      {showRevokeModal && (
        <IntegrationModal
          setShowIntegrationModal={setShowRevokeModal}
          revokeKey={revokeKey}
          regenerateKey={regenerateKey}
          deactivateWebhook={callDeactivateWebhook}
          cancelDeactivateWebhook={cancelDeactivateWebhook}
          screenToShow={screenToShow}
          removeConditionalAccess={removeConditionalAccess}
          disableConditionalAccess={disableConditionalAccess}
          cancelRemoveConditionalAccess={cancelRemoveConditionalAccess}
        />
      )}
    </>
  )
}

export default IntegrationSettings
