import { Grid } from '@radix-ui/themes'
import AvatarAndPill from '../avatar/Avatar'
import LogoGradientIcon from '../../icons/LogoGradientIcon'

const Header = () => {
  return (
    <div className="w-full absolute top-0 h-[74px]">
      <Grid columns="3" gap="3" width="100%" align="start">
        {/* iVerify Logo */}
        <div className="h-[26px] flex items-center mt-6 ml-6">
          <LogoGradientIcon height="26" width="71" />
        </div>
        {/* Search Box */}
        <div className="mt-5 flex justify-center">{/* <SearchBox /> */}</div>
        {/* Avatar */}
        <AvatarAndPill />
      </Grid>
    </div>
  )
}

export default Header
