import { useEffect } from 'react'
import { getUserSession, setSession } from '../../reducers/users'
import { useAppDispatch } from '../../reducers/store'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PageSkeleton from '../skeleton/PageSkeleton'

function SessionHydrater() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const di = useAppDispatch()

  useEffect(() => {
    fetchSession()
  }, [])

  const fetchSession = async () => {
    const session = await di(getUserSession())
    if (session) {
      navigate('/')
    } else {
      localStorage.setItem('session', '{}')
      dispatch(setSession(undefined))
      navigate('/login')
    }
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <PageSkeleton />
    </div>
  )
}

export default SessionHydrater
