/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Alert } from '../../types/AlertTypes'

export interface AlertState {
  alerts: Alert[] | undefined
  totalPages: number
  currentPage: number
}

const initialState: AlertState = {
  alerts: undefined,
  totalPages: 1,
  currentPage: 0,
}

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlerts(state, action: PayloadAction<any>) {
      state.alerts = action.payload
    },
    setTotalPages(state, action: PayloadAction<any>) {
      state.totalPages = action.payload
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload
    },
  },
})
export const selectAlerts = (state: RootState) => state.alerts

export const { setAlerts, setTotalPages, setCurrentPage } = alertsSlice.actions
export default alertsSlice.reducer
