import { useSelector } from 'react-redux'
import { selectUser } from '../../reducers/users'

const AvatarAndPill = () => {
  const { session } = useSelector(selectUser)

  return (
    <div className="flex mt-3">
      <div className="float-right mr-6 w-full">
        <div className="float-right flex mt-3">
          <p className="mr-2">{session?.organization?.name}</p>
          <p className="font-semibold">{session?.user?.name}</p>
        </div>
        {/* <Badge radius="full" size="2" variant="surface" className="h-6 mt-3 float-right text-PurpleBlue-10">
          <InfoCircledIcon />
          New iOS – 17.5 released 4/02
        </Badge> */}
        {/* <Avatar
          size="3"
          src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?&w=256&h=256&q=70&crop=focalpoint&fp-x=0.5&fp-y=0.3&fp-z=1&fit=crop"
          fallback="A"
          className="float-right"
        /> */}
      </div>
    </div>
  )
}

export default AvatarAndPill
