import { CellContext, createColumnHelper } from '@tanstack/react-table'
import { Member } from '../../types/MemberTypes'
import { DeviceInformation } from '../../types/DeviceTypes'

export const addDevicesColumns = (
  checkedItems: Record<string, boolean>,
  setCheckedItems: (checkedItems: Record<string, boolean>) => void,
  handleCheckboxChange: (email: string) => void
) => {
  const columnHelper = createColumnHelper<Member>()

  const columnHelpers = [
    {
      id: 'selection',
      cell: (row: CellContext<DeviceInformation, string>) => {
        return (
          <div className="ml-6 mt-1.5">
            <input
              key={row.row.original.email}
              id={row.row.original.email}
              type="checkbox"
              className="h-4 w-4 cursor-pointer"
              checked={checkedItems[row.row.original.email] || false}
              onChange={() => {
                setCheckedItems({ ...checkedItems, [row.row.original.email]: !checkedItems[row.row.original.email] })
                handleCheckboxChange(row.row.original.email)
              }}
            />
          </div>
        )
      },
    },
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('email', {
      header: 'Email',
    }),
  ]

  return columnHelpers
}
