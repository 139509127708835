import { configureStore } from '@reduxjs/toolkit'
import userReducer from './users'
import thunk from 'redux-thunk'
import alertsReducer from './alerts'
import devicesReducer from './devices'
import membersReducer from './members'
import settingsReducer from './settings'
import activityReducer from './activity'
import blocklistReducer from './blocklist'

export type RootState = {
  user: ReturnType<typeof userReducer>
  devices: ReturnType<typeof devicesReducer>
  alerts: ReturnType<typeof alertsReducer>
  members: ReturnType<typeof membersReducer>
  settings: ReturnType<typeof settingsReducer>
  activity: ReturnType<typeof activityReducer>
  blocklist: ReturnType<typeof blocklistReducer>
}

const createStore = (initialState?: Partial<RootState>) => {
  return configureStore({
    reducer: {
      user: userReducer,
      devices: devicesReducer,
      alerts: alertsReducer,
      members: membersReducer,
      settings: settingsReducer,
      activity: activityReducer,
      blocklist: blocklistReducer,
    },
    middleware: [thunk],
    preloadedState: initialState,
  })
}

export default createStore
