import { AvatarIcon, BellIcon, DashboardIcon, GlobeIcon, MagnifyingGlassIcon, MobileIcon } from '@radix-ui/react-icons'
import { Button } from '@radix-ui/themes'
import { useDispatch, useSelector } from 'react-redux'
import { getUserSession, selectUser, setCurrentPage } from '../../reducers/users'
import { navItems } from '../../data/nav-items'
import { IconCloudLock } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'

function NavMenu() {
  const { session, currentPage } = useSelector(selectUser)
  const dispatch = useDispatch()
  const di = useAppDispatch()
  const [updatedNavItems, setUpdatedNavItems] = useState(navItems)

  useEffect(() => {
    di(getUserSession())
  }, [])

  const selectIcon = (item: string) => {
    switch (item) {
      case 'DASHBOARD':
        return <DashboardIcon width="20" height="20" />
      case 'ALERTS':
        return <BellIcon width="20" height="20" />
      case 'ACTIVITY':
        return <GlobeIcon width="20" height="20" />
      case 'INVESTIGATIONS':
        return <MagnifyingGlassIcon width="20" height="20" />
      case 'DEVICES':
        return <MobileIcon width="20" height="20" />
      case 'MEMBERS':
        return <AvatarIcon width="20" height="20" />
      case 'BLOCKLIST':
        return <IconCloudLock width="20" height="20" />
      default:
        return <DashboardIcon width="20" height="20" />
    }
  }

  useEffect(() => {
    if (session && session.portalPreferences.localDnsFiltering) {
      setUpdatedNavItems((prevNavItems) => {
        const exists = prevNavItems.some((item) => item.type === 'BLOCKLIST')
        if (!exists) {
          return [...prevNavItems, { type: 'BLOCKLIST', display: 'Domain Blocklist' }]
        }
        return prevNavItems
      })
    }
  }, [session])

  return (
    <>
      <div className="w-[169px] mt-5">
        {updatedNavItems.map((item) => (
          <Button
            key={item.type}
            variant="solid"
            size="3"
            style={{
              justifyContent: 'left',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: '500',
            }}
            className={`h-10 !w-[176px] ${currentPage === item.type ? 'bg-PurpleBlue-10 text-white' : 'bg-white text-black'} mb-1 text-sm`}
            onClick={() => {
              localStorage.setItem('DASHBOARD_PAGE_COOKIE', item.type)
              dispatch(setCurrentPage(item.type))
            }}
          >
            {selectIcon(item.type)}
            {item.display}
          </Button>
        ))}
      </div>
    </>
  )
}

export default NavMenu
