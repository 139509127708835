import { useDispatch, useSelector } from 'react-redux'
import SettingsNavMenu from './SettingsNavMenu'
import { selectSettings, setCurrentPage } from '../../reducers/settings'
import AdminSettings from './Admin'
import { useNavigate } from 'react-router-dom'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import { Toaster } from 'react-hot-toast'
import SsoSettings from './SSO'
import ScimSettings from './SCIM'
import MdmSettings from './MDM'
import NotificationSettings from './Notifications'
import ThreatHunterSettings from './ThreatHunter'
import { selectUser, setSession } from '../../reducers/users'
import { useEffect } from 'react'
import IntegrationSettings from './Integrations'
import AuditLogs from './AuditLogs'

function SettingsWrapper() {
  const { currentPage } = useSelector(selectSettings)
  const { session } = useSelector(selectUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const storedSettingsPage = localStorage.getItem('SETTINGS_PAGE_COOKIE')

  useEffect(() => {
    if (storedSettingsPage) {
      dispatch(setCurrentPage(storedSettingsPage))
    } else {
      localStorage.setItem('SETTINGS_PAGE_COOKIE', currentPage ? currentPage : 'ADMIN')
    }
  }, [])

  const selectMainContent = () => {
    switch (currentPage) {
      case 'ADMIN':
        return <AdminSettings />
      case 'AUDIT':
        return <AuditLogs />
      case 'MDM':
        return <MdmSettings />
      case 'SCIM':
        return <ScimSettings />
      case 'SSO':
        return <SsoSettings />
      case 'INTEGRATIONS':
        return <IntegrationSettings />
      case 'NOTIFICATION':
        return <NotificationSettings />
      case 'THREAT_HUNTER':
        return <ThreatHunterSettings />
      default:
        return <AdminSettings />
    }
  }

  const selectTitle = () => {
    switch (currentPage) {
      case 'MDM':
        return 'MDM Configuration'
      case 'SCIM':
        return 'SCIM Configuration'
      case 'SSO':
        return 'Single Sign On Provider'
      case 'INTEGRATIONS':
        return 'Integrations'
      case 'NOTIFICATION':
        return 'Notifications'
      case 'THREAT_HUNTER':
        return 'Provision Threat Hunter access'
      case 'AUDIT':
        return 'Audit Logs'
      default:
        return 'Add Admins'
    }
  }

  useEffect(() => {
    const localSession = localStorage.getItem('session') as string
    if (!session && localSession === '{}') {
      localStorage.setItem('session', '{}')
      localStorage.removeItem('SSO_CONFIGURED_COOKIE')
      localStorage.removeItem('SSO_ONBOARDING_COOKIE')
      dispatch(setSession(undefined))
      navigate('/login')
    }
  }, [session])

  useEffect(() => {
    const session = localStorage.getItem('session') as string
    if (session === '{}') {
      navigate('/login')
    }
  }, [])

  return (
    <>
      <div className="max-w-[1280px] h-screen mx-auto flex gap-10 light p-8">
        {/* Nav Menu */}
        <SettingsNavMenu />
        <div className="flex flex-col gap-4 flex-grow mt-20 ">
          <div className="flex ">
            <p className="font-semibold text-xl flex-grow">{selectTitle()}</p>
            <div
              className="h-8 w-8 relative right-0 cursor-pointer"
              onClick={() => {
                localStorage.removeItem('SETTINGS_PAGE_COOKIE')
                navigate('/')
              }}
            >
              <CrossCircledIcon className="h-8 w-8" />
            </div>
          </div>
          {/* Main content */}
          {selectMainContent()}
        </div>
      </div>
      <Toaster
        toastOptions={{
          style: {
            maxWidth: 500,
          },
        }}
      />
    </>
  )
}

export default SettingsWrapper
