/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Blocklist, BlocklistLog } from '../../types/BlocklistTypes'

export interface BlocklistState {
  blocklist: Blocklist | undefined
  blocklistLogs: BlocklistLog[] | undefined
  noDomains: boolean
  noLogs: boolean
}

const initialState: BlocklistState = {
  blocklist: undefined,
  blocklistLogs: undefined,
  noDomains: false,
  noLogs: false,
}

export const blocklistSlice = createSlice({
  name: 'blocklist',
  initialState,
  reducers: {
    setBlocklist(state, action: PayloadAction<any>) {
      state.blocklist = action.payload
    },
    setBlocklistLogs(state, action: PayloadAction<any>) {
      state.blocklistLogs = action.payload
    },
    setNoDomains(state, action: PayloadAction<any>) {
      state.noDomains = action.payload
    },
    setNoLogs(state, action: PayloadAction<any>) {
      state.noLogs = action.payload
    },
  },
})
export const selectBlocklist = (state: RootState) => state.blocklist

export const { setBlocklist, setBlocklistLogs, setNoDomains, setNoLogs } = blocklistSlice.actions
export default blocklistSlice.reducer
