import { createColumnHelper } from '@tanstack/react-table'
import { Admin } from '../../types/SettingsType'
import { Button } from '@radix-ui/themes'

export const adminColumns = (
  handleResendInvite: (adminDetails: Admin) => void,
  handleDeleteAdmin: (email: string) => void
) => {
  const columnHelper = createColumnHelper<Admin>()

  const convertBoolean = (value: boolean) => {
    return value ? 'Yes' : 'No'
  }

  const showButton = (inviteAccepted: boolean, email: string, name: string) => {
    if (inviteAccepted === false) {
      return (
        <div
          className="w-20 h-9 bg-black bg-opacity-5 rounded-lg flex justify-center items-center cursor-pointer"
          onClick={() => {
            handleResendInvite({ email: email, name: name })
          }}
        >
          Resend
        </div>
      )
    } else {
      return null
    }
  }

  const showDeleteButton = (email: string) => {
    return (
      <Button
        size={'2'}
        className="cursor-pointer"
        onClick={() => {
          handleDeleteAdmin(email)
        }}
        variant="outline"
      >
        Remove admin
      </Button>
    )
  }

  return [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('email', {
      header: 'Email',
    }),
    columnHelper.accessor('inviteAccepted', {
      header: 'Invite Accepted',
      cell: (info) => convertBoolean(info.getValue()!),
    }),
    {
      id: 'button',
      cell: (row: { row: { original: Admin } }) =>
        showButton(row.row.original.inviteAccepted!, row.row.original.email, row.row.original.name),
    },
    {
      id: 'delete_button',
      cell: (row: { row: { original: Admin } }) => showDeleteButton(row.row.original.email),
    },
  ]
}
