import { FileTextIcon, RocketIcon } from '@radix-ui/react-icons'
import { Avatar } from '@radix-ui/themes'
import { useState } from 'react'
import CsvUploadPage from './CsvUploadPage'
import CsvInvitePage from './CsvInvitePage'

interface IAddSSOMembersModalProps {
  setShowCSVModal: (show: boolean) => void
}

function AddCSVMembersModal(props: IAddSSOMembersModalProps) {
  const { setShowCSVModal } = props
  const [currentPage, setCurrentPage] = useState<string>('UPLOAD')
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

  const inviteItemHighlight = () => {
    return currentPage === 'INVITE' ? 'solid' : 'soft'
  }

  const itemLine1Highlight = () => {
    return currentPage === 'INVITE' ? 'bg-PurpleBlue-10' : 'bg-PurpleBlue-4'
  }

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowCSVModal(false)}
    >
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[80%] 2xl:w-[40%] w-[70%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center">
          <p className="text-2xl font-semibold">Add Team Members By CSV</p>
        </div>
        <div className="max-w-[300px] mx-auto flex justify-between items-center mb-6 mt-4">
          <div className="flex flex-col items-center justify-center mx-2">
            <Avatar radius="full" color="violet" variant="solid" fallback={<FileTextIcon />} size="3" />
            <p className="text-sm font-semibold">Upload</p>
          </div>
          <hr className={`my-4 w-full h-1 rounded ${itemLine1Highlight()} border-0`} />
          <div className="flex flex-col items-center justify-center mx-2">
            <Avatar radius="full" color="violet" variant={inviteItemHighlight()} fallback={<RocketIcon />} size="3" />
            <p className="text-sm font-semibold">Invite</p>
          </div>
        </div>
        {currentPage === 'UPLOAD' && (
          <CsvUploadPage
            setCurrentPage={setCurrentPage}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          />
        )}
        {currentPage === 'INVITE' && (
          <CsvInvitePage
            setCurrentPage={setCurrentPage}
            setShowCSVModal={setShowCSVModal}
            uploadedFiles={uploadedFiles}
          />
        )}
      </div>
    </div>
  )
}

export default AddCSVMembersModal
