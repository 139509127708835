import { formatDistanceToNow } from 'date-fns'

export const convertTimestampToIverifyDate = (timestamp: string, enrolled: boolean) => {
  if (!enrolled) return null
  const date = new Date(timestamp)
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(2)} @ ${format24Hour(date)}`
}

const format24Hour = (date: Date) => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const hoursStr = hours < 10 ? '0' + hours.toString() : hours.toString()
  const minutesStr = minutes < 10 ? '0' + minutes.toString() : minutes.toString()
  const strTime = hoursStr + ':' + minutesStr
  return strTime
}

export const timeSince = (stringData: string) => {
  if (!stringData) return 'N/A'
  const date = new Date(stringData)
  return formatDistanceToNow(date, { addSuffix: true })
}

export const formatForDate = (stringData: number) => {
  const date = new Date(stringData * 1000)
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
}

export const formatForTimestampWithDate = (stringData: string) => {
  const date = new Date(stringData)
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
}

export const formatEpochToIverifyDate = (epochTime: number) => {
  const date = new Date(epochTime)
  const dateString = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })
  const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
  return `${dateString} @ ${timeString}`
}

export const calculateTimeLeft = (gracePeriod?: string) => {
  const now = new Date()
  const gracePeriodDate = new Date(gracePeriod ?? '')
  const diff = gracePeriodDate.getTime() - now.getTime()

  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))

  let result = ''

  if (days > 0) {
    result += `${days} days `
  }

  if (hours > 0) {
    result += `${hours} hours `
  }

  if (minutes > 0 || result === '') {
    result += `${minutes} minutes`
  }

  return result.trim()
}
