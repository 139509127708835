import { createColumnHelper } from '@tanstack/react-table'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { Button } from '@radix-ui/themes'
import { BlocklistDomain } from '../../types/BlocklistTypes'

export const domainBlocklistColumns = (
  setShowPopout: (show: boolean) => void,
  setPopoutData: (memberInfo: BlocklistDomain) => void
) => {
  const columnHelper = createColumnHelper<BlocklistDomain>()

  const columnHelpers = [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('domain', {
      header: 'Domain',
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => convertTimestampToIverifyDate(info.getValue(), true),
      header: 'Date Added',
    }),
    columnHelper.accessor('createdBy', {
      header: 'Added By',
    }),
    {
      id: 'button',
      cell: (row: { row: { original: BlocklistDomain } }) => (
        <Button
          size={'2'}
          className="cursor-pointer float-right"
          onClick={() => {
            setPopoutData(row.row.original)
            setShowPopout(true)
          }}
          variant="outline"
        >
          View
        </Button>
      ),
    },
  ]

  return columnHelpers
}
