import { Cross2Icon } from '@radix-ui/react-icons'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { BlocklistLog } from '../../types/BlocklistTypes'

interface IDomainBlocklistLogsPopoutContentProps {
  popoutData: BlocklistLog
  setShowPopout: (show: boolean) => void
}

export const DomainBlocklistLogsPopoutContent = (props: IDomainBlocklistLogsPopoutContentProps) => {
  const { popoutData, setShowPopout } = props

  return (
    <>
      {/* domain */}
      <div className="w-full">
        <div className="w-full h-20 border-b flex px-8">
          <div className="w-3/4 h-20 flex flex-col justify-center">
            <div className="text-black text-lg font-semibold leading-normal whitespace-normal max-two-lines">
              {popoutData.domain}
            </div>
          </div>
          <div className="w-1/4 h-20 flex justify-end items-center float-right">
            <div
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setShowPopout(false)
              }}
            >
              <Cross2Icon className="w-6 h-6" />
            </div>
          </div>
        </div>
        {/* blocked count */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Blocked Count</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.timesBlocked}
            </div>
          </div>
        </div>
        {/* Last occurred date */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Last Blocked</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {convertTimestampToIverifyDate(popoutData.lastOccurredAt, true)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
