/* eslint-disable @typescript-eslint/no-explicit-any */

import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { v4 as uuidv4 } from 'uuid'
import { Skeleton } from '@radix-ui/themes'

interface IDataGridProps {
  columns: any
  data: any
  loading: boolean
  classNames?: string
  sortColumn?: string | undefined
  sortOrder?: string | undefined
  sortFunction?: (column: string) => void
}

function DataGrid(props: IDataGridProps) {
  const {
    columns,
    data,
    loading,
    sortFunction,
    sortColumn,
    sortOrder,
    classNames = 'max-h-[calc(100vh_-_240px)]',
  } = props

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      {data && !loading && (
        <div className={`${classNames} overflow-y-auto border border-tableBorderGrey border-collapse rounded-lg`}>
          <table className="w-full">
            <thead
              className="bg-neutral-3 h-[56px] border-b"
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={uuidv4()} className="text-left h-[56px]">
                  {headerGroup.headers.map((header) => (
                    <th
                      key={uuidv4()}
                      className={`font-normal text-sm pl-2 ${header.column.getCanSort() && sortFunction ? 'cursor-pointer' : ''}`}
                      onClick={() => {
                        header.column.getCanSort() && sortFunction ? sortFunction(header.column.id) : null
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      <span className="p-1">
                        {header.column.id === sortColumn ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={uuidv4()} className="h-14 border-b bg-white last:border-b-0">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={uuidv4()}
                      className="text-sm pl-2"
                      style={{
                        maxWidth: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        paddingRight: '10px',
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {(!data || loading) && (
        <div className="flex justify-center items-center mt-20">
          <Skeleton className="h-[300px] w-full flex-grow" />
        </div>
      )}
    </>
  )
}

export default DataGrid
