export const API_URL = import.meta.env.VITE_API_ENDPOINT

export const LOGIN_ROUTE = '/login'
export const LOGOUT_ROUTE = '/logout'
export const RESET_PASSWORD_ROUTE = '/reset-password'
export const UPDATE_PASSWORD_ROUTE = '/update-password'
export const ORGANIZATION_LOOKUP_ROUTE = '/orgs/lookup'
export const DEVICES_ROUTE = '/orgs'
export const ANDROID_DEVICE_ROUTE = '/orgs/devices/android'
export const IOS_DEVICE_ROUTE = '/orgs/devices/ios'
export const INSECURE_DEVICE_ROUTE = '/orgs/devices/insecure'

export const ALERTS_ROUTE = '/v2/alerts'
export const ALERTS_CLOSE_ROUTE = '/close-alert'
export const SESSION_ROUTE = '/session'
export const EMAIL_USERS_ROUTE = '/orgs/email-members'
export const UNENROLL_DEVICES_ROUTE = '/orgs/unenroll-devices'
export const UPDATE_DEVICE_NAME_ROUTE = '/orgs/device-name'
export const DASHBOARD_STATS_ROUTE = '/orgs/dashboard/stats'
export const ALERT_REPORT_ROUTE = '/alerts/report'

export const MEMBERS_ROUTE = '/orgs/members'
export const ADD_MEMBERS_ROUTE = '/orgs/create-members?email=true'
export const UNBLOCK_MEMBERS_ROUTE = '/orgs/conditional-access/unblock-member'
export const REMOVE_MEMBER_ROUTE = '/orgs/remove-member'
export const REMOVE_GRACE_PERIOD_ROUTE = '/orgs/conditional-access/remove-grace-period'
export const ADMINS_ROUTE = '/orgs/admins'
export const RESEND_INVITE_ADMIN_ROUTE = '/orgs/resend-admin-invite'
export const ADD_ADMIN_ROUTE = '/orgs/add-admin'
export const DELETE_ADMIN_ROUTE = '/orgs/delete-admin'
export const SSO_ROUTE = '/orgs/sso'
export const SSO_GROUPS_ROUTE = '/orgs/sso/groups'
export const CREATE_SSO_MEMBERS_ROUTE = '/orgs/create-sso-members'
export const AUDIT_ROUTE = '/orgs/audit-logs'

export const GET_DOMAIN_BLOCKLIST_ROUTE = '/orgs/dns-blocked-list'
export const GET_DOMAIN_BLOCKLIST_LOGS_ROUTE = '/orgs/dns-blocked-list-logs'
export const POST_DOMAIN_TO_BLOCKLIST_ROUTE = '/orgs/update-dns-blocked-list'
export const DELETE_BLOCKED_DOMAIN_ROUTE = '/orgs/delete-blocked-domain'

export const CUSTOMER_BILLING_ROUTE = '/customer-billing-info'
export const CUSTOMER_PLAN_ROUTE = '/customer-plan-info'
export const API_ROUTE = '/api/token'
export const WEBHOOK_ROUTE = '/orgs/webhook'
export const WEBHOOK_SECRET_ROUTE = '/orgs/webhook-secret'
export const SCIM_ROUTE = '/orgs/scim'
export const MDM_ROUTE = '/orgs/mdm'
export const EMAIL_PREFERENCES_ROUTE = '/orgs/email-preferences'
export const NOTIFICATION_PREFERENCES_ROUTE = '/orgs/notifications-preferences?topic=OpenAppToScan'
export const ENROLLMENT_EMAIL_TEMPLATE_ROUTE = '/orgs/email-template?type=OrgEnrollment'
export const PUT_ENROLLMENT_EMAIL_TEMPLATE_ROUTE = '/orgs/email-template'
export const THREAT_HUNTER_USERS_ROUTE = '/threat-hunter/users'
export const ADD_THREAT_HUNTER_USER_ROUTE = '/threat-hunter/user'
export const CONDITIONAL_ACCESS_CONFIG_ROUTE = '/orgs/conditional-access/config'
export const CONDITIONAL_ACCESS_POLICY_ROUTE = '/orgs/conditional-access/policy'
export const CONDITIONAL_ACCESS_DELETE_ROUTE = '/orgs/conditional-access'
export const SCAN_ACTIVITY_ROUTE = '/orgs/scan-activity'
export const THREAT_HUNTER_SCAN_ACTIVITY_ROUTE = '/orgs/threat-hunter-scan-activity'
export const SYSDIAGNOSE_SCAN_ACTIVITY_ROUTE = '/orgs/sysdiagnose-scan-activity'
export const EDR_SCAN_ACTIVITY_ROUTE = '/orgs/edr-scan-activity'
export const ELITE_SCAN_ACTIVITY_ROUTE = '/orgs/elite-scan-activity'

export const EDR_SCAN_DETAILS_ROUTE = '/orgs/edr-scan-details'
export const DEEP_SCAN_DETAILS_ROUTE = '/orgs/deep-scan-details'
export const SYSDIAGNOSE_SCAN_DETAILS_ROUTE = '/orgs/sysdiagnose-scan-details'

export const DEFAULT_PAGE = 1
export const DEFAULT_PAGE_SIZE = 50
export const DASH_PAGE_SIZE = 5
export const MAXIMUM_PAGE_SIZE = 100
