/* eslint-disable @typescript-eslint/no-explicit-any */

import { InfoCircledIcon } from '@radix-ui/react-icons'
import { Button, Tooltip, Text, Separator } from '@radix-ui/themes'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import {
  deliveryOptions,
  enrollOptions,
  notificationAlertOptions,
  outdatedOptions,
  pushOptions,
  scanOptions,
} from '../../strings/dropdown-options'
import { useAppDispatch } from '../../reducers/store'
import {
  getEmailPreferences,
  getNotificationPreferences,
  putEmailPreferences,
  putNotificationPreferences,
  selectSettings,
} from '../../reducers/settings'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { EmailPreferences, NotificationPreferences } from '../../types/SettingsType'
import NotificationModal from './NotificationModal'

function NotificationSettings() {
  const di = useAppDispatch()
  const { notificationPreference, emailPreference } = useSelector(selectSettings)
  const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false)

  interface Settings {
    emailFrequency: { value: string; label: string }
    emailAlertFrequency: { value: string; label: string }
    emailOsFrequency: { value: string; label: string }
    scanFrequency: { value: string; label: string }
    pushFrequency: { value: string; label: string }
    deliveryMechanism: { value: string; label: string }
  }

  const [initialSettings, setInitialSettings] = useState({
    emailFrequency: { value: 'ONCE', label: 'Once' },
    emailAlertFrequency: { value: 'ENABLED', label: 'Enabled' },
    emailOsFrequency: { value: 'DAILY', label: 'Daily' },
    scanFrequency: { value: 'NEVER', label: 'Never' },
    pushFrequency: { value: 'DAILY', label: 'Daily' },
    deliveryMechanism: { value: 'PUSH', label: 'Push' },
  })

  const [currentSettings, setCurrentSettings] = useState(initialSettings)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await di(getEmailPreferences())
        .then(async () => await di(getNotificationPreferences()))
        .finally(() => {
          setIsLoading(false)
        })
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (!isLoading) {
      const enrollMatchingOption =
        emailPreference == undefined
          ? initialSettings.emailFrequency
          : enrollOptions.find((option) => option.value === emailPreference.enrollmentEmailsFrequency)
      const alertMatchingOption =
        emailPreference == undefined
          ? initialSettings.emailAlertFrequency
          : notificationAlertOptions.find(
              (option) => option.value === (emailPreference.threatAlerts ? 'ENABLED' : 'DISABLED')
            )
      const outdatedMatchingOption =
        emailPreference == undefined
          ? initialSettings.emailOsFrequency
          : outdatedOptions.find((option) => option.value === emailPreference.outdatedOsEmailsFrequency)
      const scanOption =
        notificationPreference == undefined
          ? initialSettings.scanFrequency
          : scanOptions.find((option) => option.value === notificationPreference.deviceIntegrityPeriod)
      const pushFrequencyOption =
        notificationPreference == undefined
          ? initialSettings.pushFrequency
          : pushOptions.find((option) => option.value === notificationPreference.notificationFrequency)
      const deliveryMechanismOption =
        notificationPreference == undefined
          ? initialSettings.deliveryMechanism
          : deliveryOptions.find((option) => option.value === notificationPreference.notificationType)

      const settings = {
        emailFrequency: enrollMatchingOption,
        emailAlertFrequency: alertMatchingOption,
        emailOsFrequency: outdatedMatchingOption,
        scanFrequency: scanOption,
        pushFrequency: pushFrequencyOption,
        deliveryMechanism: deliveryMechanismOption,
      } as Settings

      setCurrentSettings(settings)
      setInitialSettings(settings)
    }
  }, [isLoading])

  const settingsMatch = () => {
    return (
      currentSettings.emailFrequency.value === initialSettings.emailFrequency.value &&
      currentSettings.emailAlertFrequency.value === initialSettings.emailAlertFrequency.value &&
      currentSettings.emailOsFrequency.value === initialSettings.emailOsFrequency.value &&
      currentSettings.scanFrequency.value === initialSettings.scanFrequency.value &&
      currentSettings.pushFrequency.value === initialSettings.pushFrequency.value &&
      currentSettings.deliveryMechanism.value === initialSettings.deliveryMechanism.value
    )
  }

  const saveNotificationPreferences = async () => {
    const emailPayload: EmailPreferences = {
      weeklySummary: true,
      threatAlerts: currentSettings.emailAlertFrequency.value === 'ENABLED' ? true : false,
      enrollmentEmailsFrequency: currentSettings.emailFrequency.value,
      outdatedOsEmailsFrequency: currentSettings.emailOsFrequency.value,
    }

    const notificationPayload: NotificationPreferences = {
      notificationType: currentSettings.deliveryMechanism.value,
      deviceIntegrityPeriod: currentSettings.scanFrequency.value,
      notificationFrequency: currentSettings.pushFrequency.value,
      topic: 'OpenAppToScan',
    }

    const emailsSet = await di(putEmailPreferences(emailPayload))
    const notificationsSet = await di(putNotificationPreferences(notificationPayload))

    if (emailsSet && notificationsSet) {
      toast.success('Notification preferences saved successfully')
      setInitialSettings(currentSettings)
    } else {
      toast.error('Failed to save notification preferences. Please refresh the page and try again.')
    }
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: '38px',
      minHeight: '38px',
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: '30px',
      minHeight: '30px',
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    placeholder: (base: any) => ({
      ...base,
      height: '30px',
      minHeight: '30px',
      paddingTop: '0px',
    }),
    input: (base: any) => ({
      ...base,
      height: '36px',
      minHeight: '36px',
      marginTop: '0px',
      marginBottom: '0px',
      paddingTop: '0px',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  }

  return (
    <>
      <div className="w-full h-[calc(100vh_-_80px)] overflow-auto pb-8">
        <div className="rounded-xl border border-[#dae0e7] w-full overflow-auto p-3">
          <div className="h-14 bg-white rounded-t-xl">
            <Text as="div" size="2" weight="bold">
              Email settings
            </Text>
            <Separator my="3" size="4" />
            <p className="pt-[16px] ml-5 text-lg font-semibold"></p>
          </div>
          <div className="w-full">
            <div className="w-full flex flex-col">
              <div className="w-full">
                <div className="float-left">
                  <p className="text-sm font-semibold">Enrollment Emails</p>
                  <p className="text-sm">Set user onboarding enrollment email frequency</p>
                </div>
                <div className="flex float-right">
                  <Button
                    onClick={() => setShowTemplateModal(true)}
                    className="h-[38px] bg-PurpleBlue-10 cursor-pointer"
                  >
                    Customise Email
                  </Button>
                  <Select
                    value={currentSettings.emailFrequency}
                    defaultValue={enrollOptions.find((option) => option.value === 'ONCE')}
                    onChange={(e: any) => setCurrentSettings({ ...currentSettings, emailFrequency: e })}
                    options={enrollOptions}
                    className={'w-[160px] h-[36px] ml-4 cursor-pointer'}
                    menuPortalTarget={document.body}
                    styles={{ ...customStyles }}
                  />
                  <Tooltip
                    className="cursor-pointer"
                    content={
                      <span className="text-sm">
                        <p className="px-1 pt-1">
                          Sets user onboarding enrollment email frequency to one of the following options:
                        </p>
                        <ul className="px-1 pb-1">
                          <li>
                            <b>Once</b> &#8212; only send email during invitation
                          </li>
                          <li>
                            <b>Once a week</b> &#8212; email users when they are invited & every week after that until
                            they onboard
                          </li>
                          <li>
                            <b>Every second day</b> &#8212; email users when they are invited & every other day after
                            that until they onboard
                          </li>
                          <li>
                            <b>Never</b> &#8212; new onboarded users will never receive an onboarding email
                          </li>
                        </ul>
                      </span>
                    }
                  >
                    <InfoCircledIcon className="w-5 h-5 ml-2 mt-2.5 cursor-pointer" />
                  </Tooltip>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="float-left">
                  <p className="text-sm font-semibold">Threat Alert Emails</p>
                  <p className="text-sm">Set if admins should receive threat alert emails</p>
                </div>
                <div className="flex float-right">
                  <Select
                    value={currentSettings.emailAlertFrequency}
                    defaultValue={notificationAlertOptions.find((option) => option.value === 'ENABLED')}
                    onChange={(e: any) => setCurrentSettings({ ...currentSettings, emailAlertFrequency: e })}
                    options={notificationAlertOptions}
                    className={'w-[160px] h-[36px] ml-4 cursor-pointer'}
                    menuPortalTarget={document.body}
                    styles={{ ...customStyles }}
                  />
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="float-left">
                  <p className="text-sm font-semibold">Outdated OS Emails Frequency (Beta)</p>
                  <p className="text-sm">Users will receive emails when their devices have outdated OS (iOS only)</p>
                </div>
                <div className="flex float-right">
                  <Select
                    value={currentSettings.emailOsFrequency}
                    defaultValue={outdatedOptions.find((option) => option.value === 'DAILY')}
                    onChange={(e: any) => setCurrentSettings({ ...currentSettings, emailOsFrequency: e })}
                    options={outdatedOptions}
                    className={'w-[160px] h-[36px] ml-4 cursor-pointer'}
                    menuPortalTarget={document.body}
                    styles={{ ...customStyles }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-xl border border-[#dae0e7] w-full overflow-auto mt-10">
          <div className="h-16 rounded-t-xl p-3">
            <Text as="div" size="2" weight="bold">
              Device Scan Notifications (Beta)
            </Text>
            <p className="mt-2 text-sm">
              {currentSettings.scanFrequency.value !== 'NEVER'
                ? `Users will be notified with ${currentSettings.deliveryMechanism.label} Notifications ${currentSettings.pushFrequency.label} when no scans are received in the last ${currentSettings.scanFrequency.label}`
                : 'Scan reminder is disabled'}
            </p>
            <Separator my="3" size="4" />
          </div>
          <div className="w-full px-3 py-5">
            <div className="w-full flex flex-col">
              <div className="w-full">
                <div className="float-left">
                  <p className="text-sm font-semibold">Device Scan Checks Frequency</p>
                  <p className="text-sm">
                    Users will receive emails or push notifications when they haven't scanned their devices for a
                    selected interval
                  </p>
                </div>
                <div className="flex float-right">
                  <Select
                    value={currentSettings.scanFrequency}
                    defaultValue={scanOptions.find((option) => option.value === 'NEVER')}
                    onChange={(e: any) => setCurrentSettings({ ...currentSettings, scanFrequency: e })}
                    options={scanOptions}
                    className={'w-[160px] h-[36px] ml-4 cursor-pointer'}
                    menuPortalTarget={document.body}
                    styles={{ ...customStyles }}
                  />
                </div>
              </div>
              {currentSettings.scanFrequency.value !== 'NEVER' && (
                <>
                  <div className="w-full mt-8">
                    <div className="float-left">
                      <p className="text-sm font-semibold">Notification Frequency</p>
                      <p className="text-sm">Frequency of the notifications until users scan the device</p>
                    </div>
                    <div className="flex float-right">
                      <Select
                        value={currentSettings.pushFrequency}
                        defaultValue={pushOptions.find((option) => option.value === 'DAILY')}
                        onChange={(e: any) => setCurrentSettings({ ...currentSettings, pushFrequency: e })}
                        options={pushOptions}
                        className={'w-[160px] h-[36px] ml-4 cursor-pointer'}
                        menuPortalTarget={document.body}
                        styles={{ ...customStyles }}
                      />
                    </div>
                  </div>
                  <div className="w-full mt-8">
                    <div className="float-left">
                      <p className="text-sm font-semibold">Notification Delivery Mechanism</p>
                      <p className="text-sm">Users will receive either email or push notifications in the app</p>
                    </div>
                    <div className="flex float-right">
                      <Select
                        value={currentSettings.deliveryMechanism}
                        defaultValue={deliveryOptions.find((option) => option.value === 'PUSH')}
                        onChange={(e: any) => setCurrentSettings({ ...currentSettings, deliveryMechanism: e })}
                        options={deliveryOptions}
                        className={'w-[160px] h-[36px] ml-4 cursor-pointer'}
                        menuPortalTarget={document.body}
                        styles={{ ...customStyles }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end">
          {!settingsMatch() && <p className="mr-2 mt-3 text-lightGreyBlue">Unsaved Changes</p>}

          <Button
            className="h-[38px] mt-4 bg-PurpleBlue-10 cursor-pointer"
            onClick={() => saveNotificationPreferences()}
          >
            Save
          </Button>
        </div>
      </div>
      {showTemplateModal && <NotificationModal setShowModal={setShowTemplateModal} />}
    </>
  )
}

export default NotificationSettings
