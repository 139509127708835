/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { DashboardStats, DeviceInformation, InsecureDeviceInformation } from '../../types/DeviceTypes'

export interface DeviceState {
  devices: DeviceInformation[] | undefined
  totalPages: number
  currentPage: number
  insecureDevices: InsecureDeviceInformation[] | undefined
  dashboardDeviceStats: DashboardStats | undefined
}

const initialState: DeviceState = {
  devices: undefined,
  totalPages: 1,
  currentPage: 1,
  insecureDevices: undefined,
  dashboardDeviceStats: undefined,
}

export const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDevices(state, action: PayloadAction<any>) {
      state.devices = action.payload
    },
    setTotalPages(state, action: PayloadAction<any>) {
      state.totalPages = action.payload
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload
    },
    setInsecureDevices(state, action: PayloadAction<any>) {
      state.insecureDevices = action.payload
    },
    setDashboardDeviceStats(state, action: PayloadAction<any>) {
      state.dashboardDeviceStats = action.payload
    },
  },
})
export const selectDevices = (state: RootState) => state.devices

export const { setDevices, setTotalPages, setCurrentPage, setInsecureDevices, setDashboardDeviceStats } =
  deviceSlice.actions
export default deviceSlice.reducer
