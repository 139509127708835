import { createColumnHelper } from '@tanstack/react-table'
import { DeviceThreat, InsecureDeviceInformation } from '../../types/DeviceTypes'
import AlertIcon from '../../icons/AlertIcon'
import SheildIcon from '../../icons/SheildIcon'
import { Tooltip } from '@radix-ui/themes'
import { formatEpochToIverifyDate } from '../../utils/dates'

export const dashDeviceColumns = () => {
  const columnHelper = createColumnHelper<InsecureDeviceInformation>()

  return [
    columnHelper.accessor('name', {
      header: 'User',
    }),
    columnHelper.accessor('secure', {
      cell: (row) => convertSecure(row.row.original.secure, row.row.original.alertDescription),
      header: 'Threat Detected',
    }),
    columnHelper.accessor('code', {
      header: 'code',
    }),
    columnHelper.accessor('device', {
      header: 'Device',
    }),
    columnHelper.accessor('platform', {
      header: 'Platform',
    }),
    columnHelper.accessor('added', {
      header: 'Added',
      cell: (info) => formatEpochToIverifyDate(info.getValue()),
    }),
  ]
}

export const convertSecure = (secure: boolean, alertDescription: string) => {
  const threats = JSON.parse(alertDescription)
  if (!secure) {
    return (
      <Tooltip
        content={
          <>
            <p>Threats detected: </p>
            {threats.map((threat: DeviceThreat, index: number) => (
              <p key={'threat_' + index}> • {threat.threat}</p>
            ))}
          </>
        }
      >
        <div className="flex">
          <AlertIcon />
          <p className="mt-0.5 ml-1">Insecure</p>
        </div>
      </Tooltip>
    )
  } else {
    return (
      <div className="flex">
        <SheildIcon />
        <p className="mt-0.5 ml-1">Secure</p>
      </div>
    )
  }
}
