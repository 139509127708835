import { createSsoMembers, selectSettings } from '../../reducers/settings'
import { useSelector } from 'react-redux'
import { Button } from '@radix-ui/themes'
import { ArrowLeftIcon } from '@radix-ui/react-icons'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { getMembers, selectMembers } from '../../reducers/members'
import { CreateSsoMembersPayload, Group, SsoMember } from '../../types/SettingsType'
import toast from 'react-hot-toast'

interface ISsoGroupsPageProps {
  setCurrentPage: (page: string) => void
  selectedGroups: Group[]
  setShowUnenrollModal: (show: boolean) => void
}

function SsoInvitePage(props: ISsoGroupsPageProps) {
  const { setCurrentPage, selectedGroups, setShowUnenrollModal } = props
  const { ssoMembers } = useSelector(selectSettings)
  const { members } = useSelector(selectMembers)
  const [filteredMembers, setFilteredMembers] = useState<SsoMember[] | undefined>(undefined)
  const [newCodesNumber, setNewCodesNumber] = useState<number>(0)
  const [isDuplicates, setIsDuplicates] = useState<boolean>(false)
  const [noUsers, setNoUsers] = useState<boolean>(false)
  const di = useAppDispatch()

  const handleSendInvitations = async () => {
    const payload: CreateSsoMembersPayload = {
      ssoGroups: selectedGroups,
      allowedDuplicates: getAllowedDuplicates(),
    }
    const message = await di(createSsoMembers(payload))
    if (message.error) {
      toast.error(message.error, { duration: 6000 })
    } else {
      localStorage.removeItem('SSO_ONBOARDING_COOKIE')
      di(getMembers(undefined, '', undefined, undefined, undefined))
      toast.success(
        `${message.numMembersCreated} member${message.numMembersCreated === 1 ? '' : 's'} successfully created`,
        { duration: 6000 }
      )
      setShowUnenrollModal(false)
    }
  }

  useEffect(() => {
    di(getMembers())
  }, [])

  useEffect(() => {
    if (members) {
      const duplicatedMembers = fetchMembersAndSetDuplicates()
      setFilteredMembers(duplicatedMembers)
    }
  }, [members])

  const fetchMembersAndSetDuplicates = () => {
    if (!ssoMembers?.error) {
      return ssoMembers?.members.map((ssoMember) => {
        const orgDuplicate = members?.find(
          (orgMember) => orgMember.email.toLocaleLowerCase() === ssoMember.email.toLocaleLowerCase()
        )
        if (orgDuplicate) {
          setIsDuplicates(true)
          return {
            ...ssoMember,
            duplicate: true,
          }
        } else {
          return ssoMember
        }
      })
    }
  }

  useEffect(() => {
    if (ssoMembers?.error) {
      setNoUsers(true)
      toast.error(ssoMembers.error, { duration: 6000 })
    }
  }, [ssoMembers?.error])

  useEffect(() => {
    if (filteredMembers) {
      const countNonDuplicates = filteredMembers.filter((member) => !member.duplicate).length
      setNewCodesNumber(countNonDuplicates)
    }
  }, [filteredMembers])

  const setDuplicateValues = (email: string) => {
    const updatedMembers = filteredMembers!.map((filteredMember) =>
      filteredMember.email === email ? { ...filteredMember, duplicate: !filteredMember.duplicate } : filteredMember
    )
    setFilteredMembers(updatedMembers)
  }

  const getAllowedDuplicates = () => {
    return (
      filteredMembers?.filter((member) => !member.duplicate)?.map(({ name, id, email }) => ({ name, id, email })) || []
    )
  }

  const closeModal = () => {
    localStorage.removeItem('SSO_ONBOARDING_COOKIE')
    setShowUnenrollModal(false)
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="max-w-[500px]">
          <p className="text-center">
            An email will be sent to each individual with a unique code to enroll their device in your organization.
          </p>
          {isDuplicates && (
            <>
              <p className="italic text-sm mt-4 text-center">Devices are already registered for the crossed members.</p>
              <p className="italic text-sm mt-2 text-center">
                Click on the crossed members to add them back to the list and register additional devices.
              </p>
            </>
          )}
          <p className="italic text-sm mt-2 text-center">
            {newCodesNumber === 0
              ? 'No new members will be added. Undo deletion to send new access codes.'
              : `${newCodesNumber} code${newCodesNumber === 1 ? '' : 's'} will be added and billing will be updated to reflect the new count.`}
          </p>
        </div>
      </div>

      <div className="flex justify-center mt-4">
        <div className="block max-w-[700px] w-full">
          <div className="flex justify-end mt-4 cursor-pointer" onClick={() => setCurrentPage('GROUPS')}>
            <ArrowLeftIcon color="indigo" className="mt-0.5 mr-1" />
            <p className="text-PurpleBlue-10 text-sm font-semibold">Return to Previous Step</p>
          </div>
          <div className="h-[300px] border border-lightGrey rounded p-3 bg-tableHeaderGrey mt-1 break-all overflow-y-scroll">
            {filteredMembers && (
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left">Name</th>
                    <th className="text-left">Email</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMembers.map((member) => (
                    <tr
                      className={'duplicate' in member ? 'cursor-pointer' : ''}
                      onClick={() => {
                        if ('duplicate' in member) {
                          setDuplicateValues(member.email)
                        }
                      }}
                    >
                      <td className={member.duplicate ? 'line-through' : ''}>{member.name}</td>
                      <td className={member.duplicate ? 'line-through' : ''}>{member.email}</td>
                      <td>{'duplicate' in member ? 'duplicate' : ''}</td>
                      <td className="text-right text-PurpleBlue-10 cursor-pointer">
                        {member.duplicate === true ? 'add' : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {noUsers && <p className="text-center mt-4">No users found</p>}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        {!noUsers && (
          <Button
            type="submit"
            className="w-full max-w-[500px] h-[36px] rounded mt-[19px] text-white bg-PurpleBlue-10 font-semibold text-sm cursor-pointer"
            value="Add"
            onClick={handleSendInvitations}
            disabled={newCodesNumber === 0}
          >
            <p>Send Invitations</p>
          </Button>
        )}
        {noUsers && (
          <Button
            type="submit"
            className="w-full max-w-[500px] h-[36px] rounded mt-[19px] text-white font-semibold text-sm cursor-pointer"
            value="Add"
            onClick={closeModal}
          >
            <p>Finish Setup</p>
          </Button>
        )}
      </div>
    </>
  )
}

export default SsoInvitePage
