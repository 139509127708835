import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { addDomainSchema } from '../../schemas/SettingsSchema'
import { useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { Button } from '@radix-ui/themes'
import { AddDomainInputs } from '../../types/BlocklistTypes'
import { getBlocklist, postDomainToBlocklist } from '../../reducers/blocklist'
import toast from 'react-hot-toast'
import { Input } from '../../stories/input/Input'

interface IAddDomainModalProps {
  setShowModal: (show: boolean) => void
}

function AddDomainModal(props: IAddDomainModalProps) {
  const { setShowModal } = props
  const [loading, setLoading] = useState(false)
  const di = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddDomainInputs>({ resolver: yupResolver(addDomainSchema) })

  const onSubmit: SubmitHandler<AddDomainInputs> = async (domainInfo) => {
    setLoading(true)
    const successful = await di(postDomainToBlocklist(domainInfo))
    if (!successful) {
      toast.error('Failed to add domain, please try again', { duration: 6000 })
      setLoading(false)
      return
    }
    toast.success('Successfully added domain', { duration: 6000 })
    await di(getBlocklist())
    reset()
    setShowModal(false)
  }

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowModal(false)}
    >
      <div
        className="fixed w-[400px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[70%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-bold">Add Domain</h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="block mt-6"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
        >
          <div>
            <label className="text-darkGreyBlue font-semibold text-xs block">Name</label>
            <Input
              type="text"
              placeholder="Phishing CDN"
              {...register('name', { required: true })}
              onChange={undefined}
              maxLength={80}
            />
            {errors.name && <p className="text-FieryGarnet">{errors.name.message}</p>}
          </div>
          <div className="mt-6">
            <label className="text-darkGreyBlue font-semibold text-xs block">Domain</label>
            <Input
              type="text"
              placeholder="cdn.example.com"
              {...register('domain', { required: true })}
              onChange={undefined}
              maxLength={160}
            />
            {errors.domain && <p className="text-FieryGarnet">{errors.domain.message}</p>}
          </div>
          <div className="flex justify-end mt-6">
            <button className="px-4 py-2 mr-2 bg-gray-300 rounded" onClick={() => setShowModal(false)}>
              Cancel
            </button>
            <Button
              className={`h-10 px-4 py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ' cursor-pointer'} bg-PurpleBlue-10 text-white`}
              type="submit"
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Add'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddDomainModal
