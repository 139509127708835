/* eslint-disable @typescript-eslint/no-explicit-any */

import { Skeleton } from '@radix-ui/themes'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { v4 as uuidv4 } from 'uuid'

interface IDataGridProps {
  columns: any
  data: any
  loading: boolean
  small?: boolean
}

function DashboardDataGrid(props: IDataGridProps) {
  const { columns, data, loading, small } = props

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      {data && !loading && (
        <div className={`${small ? `max-h-[400px]` : 'max-h-[calc(100vh_-_240px)]'} overflow-y-auto`}>
          <table className="w-full">
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={uuidv4()} className={`h-14 border-b border-GridDividerGrey last:border-b-0`}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={uuidv4()}
                      className="text-sm pl-2"
                      style={{
                        maxWidth: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        paddingRight: '10px',
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {(!data || loading) && (
        <div className="flex justify-center items-center mt-20">
          <Skeleton className="h-[300px] w-full flex-grow" />
        </div>
      )}
    </>
  )
}

export default DashboardDataGrid
