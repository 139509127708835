import { IconChevronRight, IconChevronLeft } from '@tabler/icons-react'
import ReactPaginate from 'react-paginate'

export interface IPaginationProps {
  forcePage: number
  totalPages: number
  pageRangeDisplayed?: number
  marginPagesDisplayed?: number
  handlePageClick: (selectedItem: { selected: number }) => void
}

export const Pagination = ({
  totalPages,
  forcePage,
  handlePageClick,
  pageRangeDisplayed = 2,
  marginPagesDisplayed = 2,
}: IPaginationProps) => {
  const onPageChange = (selectedItem: { selected: number }) => {
    handlePageClick(selectedItem)
  }

  return (
    <div className="flex justify-center items-center">
      <ReactPaginate
        className="react-paginate flex space-x-2 items-center select-none"
        pageLinkClassName="border rounded-md px-3 py-1 flex"
        activeLinkClassName="!bg-neutral-12 text-white !border-none"
        breakLabel="•••"
        breakLinkClassName="bg-transparent"
        nextLabel={<IconChevronRight className="h-4 w-4" />}
        nextLinkClassName="border rounded-md p-2 flex"
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        pageCount={totalPages}
        forcePage={forcePage}
        previousClassName=""
        previousLinkClassName="border rounded-md p-2 flex"
        previousLabel={<IconChevronLeft className="h-4 w-4" />}
        renderOnZeroPageCount={null}
        onPageChange={onPageChange}
        disabledLinkClassName="!border-neutral-4 text-neutral-8 cursor-default"
        disableInitialCallback={true}
      />
    </div>
  )
}
