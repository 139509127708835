import toast from 'react-hot-toast'
import { deleteAdmin, getAdmins } from '../../reducers/settings'
import { useAppDispatch } from '../../reducers/store'

interface IDeleteAdminModalProps {
  setShowModal: (show: boolean) => void
  deleteAdminEmail: string
}

function DeleteAdminModal(props: IDeleteAdminModalProps) {
  const { setShowModal, deleteAdminEmail } = props
  const di = useAppDispatch()

  const handleDeleteAdmin = async () => {
    const deleted = await di(deleteAdmin(deleteAdminEmail))
    if (!deleted) {
      toast.error('An error occurred while deleting the admin', { duration: 6000 })
    } else {
      toast.success(`Admin ${deleteAdminEmail} has been deleted`, { duration: 6000 })
    }
    di(getAdmins())
    setShowModal(false)
  }

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowModal(false)}
    >
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[80%] w-[30%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="block">
          <p className="text-2xl font-semibold text-center">Delete Admin</p>
          <p className="mt-4 text-center">
            Are you sure you want to delete {deleteAdminEmail}? This admin will no longer have access to the admin
            portal.
          </p>
        </div>

        <div className="flex justify-end mt-6">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded-lg"
            onClick={() => {
              setShowModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-ErrorRed text-white rounded-lg"
            onClick={() => {
              handleDeleteAdmin()
            }}
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteAdminModal
