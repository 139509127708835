export interface AlertResponse {
  alerts: Alert[]
  totalPages: number
}

export interface AlertReportResponse {
  statusCode: number
  message: string
  download_url?: string
}

export interface Alert {
  id: number
  alertName: string
  scanTime: string
  createdAt: string
  updatedAt: string
  scanMethod: ScanMethod
  severity: Severity
  alertStatus: AlertStatus
  scannedByAccessCode: string
  alertDescription: string
  scanId: string
  device: string
  memberName: string
  platform: string
  osVersion: string
  scannedBy?: string
}

export enum Severity {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  SUSPICIOUS = 'SUSPICIOUS',
  LOW = 'LOW',
}

export enum AlertStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum ScanMethod {
  IVY_APP = 'IVY_APP',
  THREAT_HUNTER = 'THREAT_HUNTER',
  APP_SYSDIAGNOSE = 'APP_SYSDIAGNOSE',
  APP_BUG_REPORT = 'APP_BUG_REPORT',
  IVERIFY_ELITE = 'IVERIFY_ELITE',
}
