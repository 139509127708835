import DropZone from '../drop-zone/DropZone'
import { Button } from '@radix-ui/themes'

interface ICsvUploadPageProps {
  setCurrentPage: (page: string) => void
  uploadedFiles: File[]
  setUploadedFiles: (files: File[]) => void
}

function CsvUploadPage(props: ICsvUploadPageProps) {
  const { setCurrentPage, uploadedFiles, setUploadedFiles } = props

  const TemplateLink = () => (
    <a
      className="flex justify-center text-PurpleBlue-10 font-semibold"
      href="data:text/csv;charset=UTF-8,fullname%2Cemail%0AJohn%20Smith%2Cperson%40yourOrganization.com"
      download="member_template.csv"
    >
      Download Example Template
    </a>
  )

  return (
    <>
      <div className="block">
        <p className="flex justify-center">You will be able to confirm data before sending invitations.</p>
        <p className="flex justify-center mt-4 text-sm">CSV must include "fullname" and "email" columns.</p>
      </div>
      {TemplateLink()}
      <DropZone uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
      <Button
        className="mt-4 flex justify-center mx-auto cursor-pointer bg-PurpleBlue-10 text-white"
        disabled={uploadedFiles.length === 0}
        onClick={() => setCurrentPage('INVITE')}
      >
        Upload
      </Button>
    </>
  )
}

export default CsvUploadPage
