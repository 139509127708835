import { Avatar, Button } from '@radix-ui/themes'
import { Cross2Icon } from '@radix-ui/react-icons'
import { useEffect } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { deleteSsoSettings } from '../../reducers/settings'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from '../../reducers/users'

function SsoErrorWrapper() {
  const di = useAppDispatch()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    localStorage.removeItem('SSO_CONFIGURED_COOKIE')
    localStorage.removeItem('SSO_ONBOARDING_COOKIE')
    di(deleteSsoSettings())
  }, [])

  const logoutUser = () => {
    dispatch(setCurrentPage('DASHBOARD'))
    navigate('/login')
  }

  return (
    <div className="light flex">
      <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] bg-neutral-3 p-9">
        <div className="flex flex-col items-center mt-20">
          <Avatar radius="full" color="red" fallback={<Cross2Icon height={40} width={40} />} size="6" />
          <p className="max-w-[500px] text-center font-bold text-PurpleBlue-10 text-2xl mt-6">An error has occurred</p>
          <p className="max-w-[500px] text-center font-bold text-lg mt-2">
            You are not authorized to access this organization
          </p>
          <p className="max-w-[500px] text-center mt-4">Please contact your organization administrator.</p>
          <p className="max-w-[500px] text-center">
            You can also refer to our{' '}
            <a
              href="https://iverify.io/frequently-asked-questions"
              target="_blank"
              className="text-PurpleBlue-10 font-semibold"
            >
              FAQs
            </a>{' '}
            or contact us at{' '}
            <a href="mailto:support@iverify.io" target="_blank" className="text-PurpleBlue-10 font-semibold">
              support@iverify.io
            </a>
          </p>
          <Button className="h-[38px] bg-PurpleBlue-10 cursor-pointer mt-4" onClick={() => logoutUser()}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SsoErrorWrapper
