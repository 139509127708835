import { Cross2Icon, DesktopIcon, MobileIcon, ReaderIcon } from '@radix-ui/react-icons'
import AlertIcon from '../../icons/AlertIcon'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { ScanMethod } from '../../types/AlertTypes'
import { EdrScanDetails, Threat } from '../../types/ActivityTypes'
import checkIcon from '../../assets/check_circle.svg'

interface IEdrPopoutContentProps {
  popoutData: EdrScanDetails
  setShowPopout: (show: boolean) => void
}

export const EdrPopoutContent = (props: IEdrPopoutContentProps) => {
  const { popoutData, setShowPopout } = props

  const handleScanMethod = (scanMethod: string) => {
    switch (scanMethod) {
      case ScanMethod.IVY_APP:
        return (
          <div className="flex">
            <MobileIcon className="mt-0.5 mr-2" />
            <p>EDR App</p>
          </div>
        )
      case ScanMethod.THREAT_HUNTER:
        return (
          <div className="flex">
            <DesktopIcon className="mt-0.5 mr-2" />
            <p>Threat Hunter</p>
          </div>
        )
      case ScanMethod.APP_SYSDIAGNOSE:
        return (
          <div className="flex">
            <ReaderIcon className="mt-0.5 mr-2" />
            <p>Sysdiagnose</p>
          </div>
        )
      case ScanMethod.IVERIFY_ELITE:
        return (
          <div className="flex">
            <MobileIcon className="mt-0.5 mr-2" />
            <p>Elite Scan</p>
          </div>
        )
      default:
        return null
    }
  }

  const booleanToYesNo = (value: boolean) => {
    if (value === null) return 'Unknown'
    return value ? 'Yes' : 'No'
  }

  const parseDetailsJson = (details: string) => {
    try {
      return JSON.parse(details)
    } catch (e) {
      return null
    }
  }

  const generateDetailsView = (details: string) => {
    const parsedDetails = parseDetailsJson(details)
    if (!parsedDetails) {
      return (
        <div className="block items-center mt-0">
          <div className="flex items-center justify-center">
            <img src={checkIcon} alt="No open alerts found" className="h-12 w-12" />
          </div>{' '}
          <p className="text-center font-bold">No Alert</p>
          <p className="text-center text-sm">You have no actions for now</p>
        </div>
      )
    }
    return (
      <div className="w-full mt-2">
        {parsedDetails.map((check: Threat) => {
          return <p className="text-sm">• {check.threat}</p>
        })}
      </div>
    )
  }

  return (
    <>
      {popoutData.alertCreated && (
        <div className={`w-full h-[52px] flex justify-center items-center border-b text-[#cb1d63] bg-[#f0004715]`}>
          <AlertIcon iconFill={'#cb1d63'} />
          <p className="text-xs font-semibold">This scan created an alert</p>
        </div>
      )}

      {/* Name */}
      <div className="w-full">
        <div className="w-full h-20 border-b flex px-8">
          <div className="w-3/4 h-20 flex flex-col justify-center">
            <div className="text-black text-lg font-semibold leading-normal whitespace-normal max-two-lines">
              {popoutData.memberName}
            </div>
            <div className="text-black text-opacity-50 text-sm font-semibold leading-normal">{popoutData.model}</div>
          </div>
          <div className="w-1/4 h-20 flex justify-end items-center float-right">
            <div
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setShowPopout(false)
              }}
            >
              <Cross2Icon className="w-6 h-6" />
            </div>
          </div>
        </div>
        {/* Alert Name */}
        <div className="w-full h-[auto] border-b px-8 pb-4">
          <div className="flex">
            <div className="w-1/2 h-12 flex flex-col justify-center">
              <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Alert Details</div>
            </div>
          </div>
          {generateDetailsView(popoutData.details)}
        </div>
        {/* Scan time */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Scan Time</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {convertTimestampToIverifyDate(popoutData.createdAt, true)}
            </div>
          </div>
        </div>
        {/* Scanned by */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Access Code</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.accessCode}
            </div>
          </div>
        </div>
        {/* Scan method */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Scan Type</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {handleScanMethod(popoutData.scanType)}
            </div>
          </div>
        </div>
        {/* Platform */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Platform</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.platform}
            </div>
          </div>
        </div>
        {/* OS */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">OS</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.osVersion}
            </div>
          </div>
        </div>
        {/* biometrics */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Biometrics Enabled</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {booleanToYesNo(popoutData.biometrics)}
            </div>
          </div>
        </div>
        {/* isNotificationsEnabled */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">
              Notifications Enabled
            </div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {booleanToYesNo(popoutData.isNotificationsEnabled)}
            </div>
          </div>
        </div>
        {popoutData.platform === 'ANDROID' && (
          <>
            {/* isPlayProtectEnabled */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">
                  Play Protect Enabled
                </div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {booleanToYesNo(popoutData.isPlayProtectEnabled)}
                </div>
              </div>
            </div>
            {/* isDeveloperModeEnabled */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">
                  Developer Mode Enabled
                </div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {booleanToYesNo(popoutData.isDeveloperModeEnabled)}
                </div>
              </div>
            </div>
            {/* isSideloadingEnabled */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">
                  Side Loading Enabled
                </div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {booleanToYesNo(popoutData.isSideloadingEnabled)}
                </div>
              </div>
            </div>
            {/* isAdbEnabled */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">ADB Enabled</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {booleanToYesNo(popoutData.isAdbEnabled)}
                </div>
              </div>
            </div>
            {/* isWorkProfile */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Has Work Profile</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {booleanToYesNo(popoutData.isWorkProfile)}
                </div>
              </div>
            </div>
          </>
        )}
        {popoutData.platform === 'IOS' && (
          <>
            {/* lockdownEnabled */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Lockdown Enabled</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {booleanToYesNo(popoutData.lockdownEnabled)}
                </div>
              </div>
            </div>
          </>
        )}
        {/* screenLock */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Screen Lock Enabled</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {booleanToYesNo(popoutData.screenLock)}
            </div>
          </div>
        </div>

        {/* smishingEnabled */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Smishing Enabled</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {booleanToYesNo(popoutData.smishingEnabled)}
            </div>
          </div>
        </div>
        <div className="flex items-center pt-4" />
      </div>
    </>
  )
}
