import './styles.css'
import DevicePopoutContent from '../devices/DevicePopoutContent'
import { AlertPopoutContent } from '../alert-console/AlertPopoutContent'
import { MemberPopoutContent } from '../members/MemberPopoutContent'
import { DeviceInformation } from '../../types/DeviceTypes'
import { EdrPopoutContent } from '../activity/EdrPopoutContent'
import { DeepPopoutContent } from '../activity/DeepPopoutContent'
import { DomainBlocklistPopoutContent } from '../domain-blocklist/DomainBlocklistPopoutContent'
import AuditPopoutContent from '../settings/AuditPopoutContent'
import { DomainBlocklistLogsPopoutContent } from '../domain-blocklist/DomainBlocklistLogsPopoutContent'

interface IPopoutProps {
  setShowPopout: (show: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popoutData: any
  contentScreen: string
  setClickedInputs?: (clickedInputs: DeviceInformation[]) => void
  setShowUnenrollModal?: (show: boolean) => void
  setShowResolveModal?: (show: boolean) => void
  handleReloadingDevicesAfterUpdate?: () => void
  heightOffset?: number
}

const Popout = (props: IPopoutProps) => {
  const {
    setShowPopout,
    contentScreen,
    popoutData,
    setClickedInputs,
    setShowUnenrollModal,
    setShowResolveModal,
    handleReloadingDevicesAfterUpdate,
    heightOffset = 74,
  } = props

  const determineContent = () => {
    if (popoutData) {
      switch (contentScreen) {
        case 'DEVICE':
          return (
            <DevicePopoutContent
              setShowPopout={setShowPopout}
              popoutData={popoutData}
              setClickedInputs={setClickedInputs!}
              setShowUnenrollModal={setShowUnenrollModal!}
              handleReloadingDevicesAfterUpdate={handleReloadingDevicesAfterUpdate!}
            />
          )
        case 'EDR':
          return <EdrPopoutContent setShowPopout={setShowPopout} popoutData={popoutData} />
        case 'DEEP':
          return <DeepPopoutContent setShowPopout={setShowPopout} popoutData={popoutData} />
        case 'ALERT':
          return (
            <AlertPopoutContent
              setShowPopout={setShowPopout}
              popoutData={popoutData}
              setShowResolveModal={setShowResolveModal!}
            />
          )
        case 'MEMBERS':
          return <MemberPopoutContent setShowPopout={setShowPopout} popoutData={popoutData} />
        case 'DOMAINS':
          return <DomainBlocklistPopoutContent setShowPopout={setShowPopout} popoutData={popoutData} />
        case 'REQUESTS':
          return <DomainBlocklistLogsPopoutContent setShowPopout={setShowPopout} popoutData={popoutData} />
        case 'AUDIT':
          return <AuditPopoutContent setShowPopout={setShowPopout} popoutData={popoutData} />
        default:
          return null
      }
    } else {
      return null
    }
  }

  return (
    <div
      className={`${heightOffset > 0 ? `h-[calc(100vh_-_${heightOffset}px)] absolute` : 'top-0 h-screen fixed'}  w-[400px]  right-0 bg-white font-['Open Sans'] z-50 border-t border-l shadow-2xl overflow-y-auto`}
    >
      {determineContent()}
    </div>
  )
}

export default Popout
