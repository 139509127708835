interface IAlertIconProps {
  iconFill?: string
}

const AlertIcon = (props: IAlertIconProps) => {
  const { iconFill } = props
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87301 4.88103L4.88103 9.87301C3.70632 11.0477 3.70632 12.9523 4.88103 14.127L9.87301 19.119C11.0477 20.2937 12.9523 20.2937 14.127 19.119L19.119 14.127C20.2937 12.9523 20.2937 11.0477 19.119 9.87301L14.127 4.88103C12.9523 3.70632 11.0477 3.70632 9.87301 4.88103ZM5.94169 10.9337L10.9337 5.94169C11.5226 5.35277 12.4774 5.35277 13.0663 5.94169L18.0583 10.9337C18.6472 11.5226 18.6472 12.4774 18.0583 13.0663L13.0663 18.0583C12.4774 18.6472 11.5226 18.6472 10.9337 18.0583L5.94169 13.0663C5.35277 12.4774 5.35277 11.5226 5.94169 10.9337ZM12.75 15.1228C12.6914 15.4786 12.3824 15.75 12.01 15.75H12C11.6276 15.75 11.3186 15.4786 11.26 15.1228C11.2534 15.0828 11.25 15.0418 11.25 15C11.25 14.9582 11.2534 14.9172 11.26 14.8772C11.3186 14.5214 11.6276 14.25 12 14.25H12.01C12.3824 14.25 12.6914 14.5214 12.75 14.8772C12.7566 14.9172 12.76 14.9582 12.76 15C12.76 15.0418 12.7566 15.0828 12.75 15.1228ZM12.75 8.75C12.75 8.33579 12.4142 8 12 8C11.5858 8 11.25 8.33579 11.25 8.75V12.25C11.25 12.6642 11.5858 13 12 13C12.4142 13 12.75 12.6642 12.75 12.25V8.75Z"
        fill={iconFill ? iconFill : '#B02145'}
      />
    </svg>
  )
}

export default AlertIcon
