import { useDispatch, useSelector } from 'react-redux'
import LoginForm from './LoginForm'
import ResetPasswordForm from './ResetPasswordForm'
import { getUserSession, selectUser, setHasOrgSso } from '../../reducers/users'
import { useEffect } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { useNavigate } from 'react-router-dom'
import LogoGradientIcon from '../../icons/LogoGradientIcon'
import loginBackground from '../../assets/login_background.svg'
import '../../index.css'

function LoginWrapper() {
  const { loginPage } = useSelector(selectUser)
  const di = useAppDispatch()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    checkSession()
    return () => {
      dispatch(setHasOrgSso(undefined))
    }
  }, [])

  const checkSession = async () => {
    const session = await di(getUserSession())
    if (session) {
      navigate('/')
    }
  }

  const switchLoginScreen = () => {
    switch (loginPage) {
      case 'LOGIN':
        return <LoginForm />
      case 'FORGOT':
        return <ResetPasswordForm />
      default:
        return <LoginForm />
    }
  }

  return (
    <div className="flex light h-screen bg-neutral-2">
      <div className="absolute top-6 left-6">
        <LogoGradientIcon height="26" width="71" />
      </div>
      <div className="flex flex-col lg:flex-row w-full">
        <div className="w-full my-52 w-1/2 lg:my-0 flex items-center justify-center">
          {loginPage && switchLoginScreen()}
        </div>
        <div className="hidden lg:flex w-1/2 items-start justify-start pr-8 py-8 relative mt-0">
          <div className="absolute bottom-8 p-8 pr-16">
            <p className="text-white text-2xl lg:text-3xl font-medium">
              A Privacy-Conscious Platform
              <br />
              for Advanced Threats
            </p>
            <p className="text-white text-lg lg:text-xl">
              Stay ahead of zero-day attacks, threats,
              <br />
              and vulnerabilities
            </p>
          </div>
          <img
            src={loginBackground}
            alt="Login page background"
            className="w-full h-full object-cover rounded-[32px]"
          />
        </div>
      </div>
    </div>
  )
}

export default LoginWrapper
