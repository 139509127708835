export const navItems = [
  {
    type: 'DASHBOARD',
    display: 'Dashboard',
  },
  {
    type: 'ACTIVITY',
    display: 'Activity Feed',
  },
  {
    type: 'ALERTS',
    display: 'Alerts Console',
  },
  {
    type: 'DEVICES',
    display: 'Devices',
  },
  {
    type: 'MEMBERS',
    display: 'Members',
  },
]
