import { Skeleton } from '@radix-ui/themes'

const ContentSkeleton = () => {
  return (
    <div className="flex flex-col">
      <Skeleton className="h-7 w-20 mt-3" />
      <Skeleton className="h-4 w-36 mt-2" />
    </div>
  )
}

export default ContentSkeleton
