import {
  API_URL,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  ORGANIZATION_LOOKUP_ROUTE,
  RESET_PASSWORD_ROUTE,
  SESSION_ROUTE,
  UPDATE_PASSWORD_ROUTE,
} from '../../strings/api-consts'
import { CustomThunkDispatch, OrganizationLookup, SessionState, UpdatePasswordPayload } from '../../types/UserTypes'
import { setHasOrgSso, setLoginPage, setSession } from './users.slice'
import * as Sentry from '@sentry/react'

export const lookupOrganization = (organizationName: string) => async (dispatch: CustomThunkDispatch) => {
  const email = organizationName.toLowerCase().replace(/ /g, '')

  await fetch(`${API_URL + ORGANIZATION_LOOKUP_ROUTE}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: email }),
  })
    .then((res) => res.json())
    .then((organization: OrganizationLookup) => {
      if (organization.sso) {
        window.location.href = import.meta.env.VITE_API_ENDPOINT + `/oauth/login?slug=${organization.slug}`
        return
      }
      dispatch(setHasOrgSso(false))
      dispatch(setLoginPage('LOGIN'))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      dispatch(setLoginPage('LOGIN'))
    })
}

export const loginUser = (email: string, password: string) => async (dispatch: CustomThunkDispatch) => {
  return fetch(`${API_URL + LOGIN_ROUTE}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify({ email: email, password: password }),
  })
    .then((res) => res.json())
    .then((session: SessionState) => {
      if (session.error) {
        localStorage.setItem('session', '{}')
        dispatch(setSession(undefined))
        return { error: session.error }
      }
      localStorage.setItem('session', JSON.stringify(session))
      dispatch(setSession(session))
      return session
    })
    .catch(() => {
      localStorage.setItem('session', '{}')
      dispatch(setSession(undefined))
      return { error: 'An error has occurred. Please try again or contact your administrator' }
    })
}

export const resetPassword = (email: string) => async () => {
  await fetch(`${API_URL + RESET_PASSWORD_ROUTE}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: email }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
    })
}

export const logoutUser = () => async (dispatch: CustomThunkDispatch) => {
  await fetch(`${API_URL + LOGOUT_ROUTE}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
    .then(() => {
      localStorage.setItem('session', '{}')
      dispatch(setLoginPage('LOGIN'))
      dispatch(setSession(undefined))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      localStorage.setItem('session', '{}')
    })
}

export const getUserSession = () => async (dispatch: CustomThunkDispatch) => {
  return fetch(`${API_URL + SESSION_ROUTE}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((session: SessionState) => {
      if (session.user.email) {
        localStorage.setItem('session', JSON.stringify(session))
        dispatch(setSession(session))
        return session
      }
    })
    .catch(() => {
      localStorage.setItem('session', '{}')
      dispatch(setLoginPage('LOGIN'))
      dispatch(setSession(undefined))
      return undefined
    })
}

export const updatePassword = (payload: UpdatePasswordPayload) => async () => {
  try {
    const response = await fetch(`${API_URL + UPDATE_PASSWORD_ROUTE}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    if (response.ok) {
      const text = await response.text()
      return text ? JSON.parse(text) : {}
    } else {
      const errorResponse = await response.json()
      return { error: errorResponse.error }
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.error(err.message)
      return { error: err.message }
    } else {
      console.error('An unknown error occurred')
      return { error: 'An unknown error occurred' }
    }
  }
}
