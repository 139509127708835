import { FileTextIcon, GearIcon, PersonIcon, RocketIcon } from '@radix-ui/react-icons'
import { Avatar } from '@radix-ui/themes'
import { useEffect, useState } from 'react'
import SsoSettingsPage from './SsoSettingsPage'
import SsoGroupsPage from './SsoGroupsPage'
import SsoInvitePage from './SsoInvitePage'
import { Group } from '../../types/SettingsType'
import SsoConfirmedPage from './SsoConfirmedPage'
import { SessionState } from '../../types/UserTypes'

interface IAddSSOMembersModalProps {
  setShowUnenrollModal: (show: boolean) => void
  goToGroups: boolean
}

function AddSSOMembersModal(props: IAddSSOMembersModalProps) {
  const { setShowUnenrollModal, goToGroups } = props
  const [currentPage, setCurrentPage] = useState<string>('SETTINGS')
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([])

  const configuredItemHighlight = () => {
    return currentPage === 'CONFIGURED' || currentPage === 'GROUPS' || currentPage === 'INVITE' ? 'solid' : 'soft'
  }

  const groupsItemHighlight = () => {
    return currentPage === 'GROUPS' || currentPage === 'INVITE' ? 'solid' : 'soft'
  }

  const inviteItemHighlight = () => {
    return currentPage === 'INVITE' ? 'solid' : 'soft'
  }

  const groupsItemLine1Highlight = () => {
    return currentPage === 'CONFIGURED' || currentPage === 'INVITE' || currentPage === 'GROUPS'
      ? 'bg-PurpleBlue-10'
      : 'bg-PurpleBlue-4'
  }

  const groupsItemLine2Highlight = () => {
    return currentPage === 'GROUPS' ? 'bg-PurpleBlue-10' : 'bg-PurpleBlue-4'
  }

  const groupsItemLine3Highlight = () => {
    return currentPage === 'INVITE' ? 'bg-PurpleBlue-10' : 'bg-PurpleBlue-4'
  }

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('session') as string) as SessionState
    const goToConfigured = localStorage.getItem('SSO_CONFIGURED_COOKIE') as string

    if (goToConfigured === 'true') {
      setCurrentPage('CONFIGURED')
      return
    }
    if (session.organization.ssoProvider) {
      setCurrentPage('GROUPS')
      return
    }
    if (goToGroups) {
      setCurrentPage('GROUPS')
      return
    }
  }, [goToGroups])

  const closeModal = () => {
    localStorage.removeItem('SSO_CONFIGURED_COOKIE')
    setShowUnenrollModal(false)
  }

  return (
    <div className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50" onClick={() => closeModal()}>
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[80%] w-[40%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center">
          <p className="text-2xl font-semibold">Add Team Members Using SSO</p>
        </div>
        <div className="max-w-[500px] mx-auto flex justify-between items-center mb-6 mt-4">
          <div className="flex flex-col items-center justify-center mx-2">
            <Avatar radius="full" color="violet" variant="solid" fallback={<FileTextIcon />} size="3" />
            <p className="text-sm font-semibold">Settings</p>
          </div>
          <hr className={`my-4 w-full h-1 rounded ${groupsItemLine1Highlight()} border-0`} />
          <div className="flex flex-col items-center justify-center mx-2">
            <Avatar radius="full" color="violet" variant={configuredItemHighlight()} fallback={<GearIcon />} size="3" />
            <p className="text-sm font-semibold">Configured</p>
          </div>
          <hr className={`my-4 w-full h-1 rounded ${groupsItemLine2Highlight()} border-0`} />
          <div className="flex flex-col items-center justify-center mx-2">
            <Avatar radius="full" color="violet" variant={groupsItemHighlight()} fallback={<PersonIcon />} size="3" />
            <p className="text-sm font-semibold">Groups</p>
          </div>
          <hr className={`my-4 w-full h-1 rounded ${groupsItemLine3Highlight()} border-0`} />
          <div className="flex flex-col items-center justify-center mx-2">
            <Avatar radius="full" color="violet" variant={inviteItemHighlight()} fallback={<RocketIcon />} size="3" />
            <p className="text-sm font-semibold">Invite</p>
          </div>
        </div>
        {currentPage === 'SETTINGS' && <SsoSettingsPage />}
        {currentPage === 'CONFIGURED' && (
          <SsoConfirmedPage setCurrentPage={setCurrentPage} setShowUnenrollModal={setShowUnenrollModal} />
        )}
        {currentPage === 'GROUPS' && (
          <SsoGroupsPage
            setCurrentPage={setCurrentPage}
            setSelectedGroups={setSelectedGroups}
            setShowUnenrollModal={setShowUnenrollModal}
          />
        )}
        {currentPage === 'INVITE' && (
          <SsoInvitePage
            setCurrentPage={setCurrentPage}
            selectedGroups={selectedGroups}
            setShowUnenrollModal={setShowUnenrollModal}
          />
        )}
      </div>
    </div>
  )
}

export default AddSSOMembersModal
