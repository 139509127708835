import toast from 'react-hot-toast'
import { deleteSsoSettings, updateMdmStatus, updateScimStatus } from '../../reducers/settings'
import { useAppDispatch } from '../../reducers/store'
import { useDispatch } from 'react-redux'
import { setSession } from '../../reducers/users'
import { useNavigate } from 'react-router-dom'

interface IDisconnectModalProps {
  setShowModal: (show: boolean) => void
  screen: string
}

function DisconnectModal(props: IDisconnectModalProps) {
  const { setShowModal, screen } = props
  const di = useAppDispatch()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleDisconnect = async () => {
    if (screen === 'SCIM') {
      await di(updateScimStatus(false))
      setShowModal(false)
      toast.success('SCIM has been successfully disconnected', { duration: 6000 })
      return
    } else if (screen === 'SSO') {
      const deleted = await di(deleteSsoSettings())
      if (deleted) {
        toast.success('Successfully disconnected SSO', { duration: 6000 })
        localStorage.setItem('session', '{}')
        localStorage.removeItem('SSO_ONBOARDING_COOKIE')
        localStorage.removeItem('SSO_CONFIGURED_COOKIE')
        dispatch(setSession(undefined))
        setShowModal(false)
        navigate('/login')
      } else {
        toast.error('Failed to disconnect SSO', { duration: 6000 })
      }
    } else if (screen === 'MDM') {
      await di(updateMdmStatus(false))
      setShowModal(false)
      toast.success('MDM has been successfully disconnected', { duration: 6000 })
      return
    }
  }

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowModal(false)}
    >
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[80%] w-[40%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center">
          <p className="text-2xl font-semibold">Disconnect {screen}</p>
        </div>
        <p className="text-center">
          {screen === 'SSO' && 'Are you sure you want to disconnect your Single Sign On Provider?'}
          {screen === 'SCIM' && (
            <>
              Are you sure you want to disconnect SCIM and manage members manually?
              <br />
              All current members will be retained.
            </>
          )}
          {screen === 'MDM' && (
            <>
              Are you sure you want to disable MDM?
              <br />
              All current members will be retained.
            </>
          )}
        </p>

        <div className="flex justify-center mt-6">
          <button className="px-4 py-2 mr-2 bg-gray-300 rounded-lg" onClick={() => setShowModal(false)}>
            Cancel
          </button>
          <button className="px-4 py-2 bg-ErrorRed text-white rounded-lg" onClick={() => handleDisconnect()}>
            Yes, Disconnect
          </button>
        </div>
      </div>
    </div>
  )
}

export default DisconnectModal
