import {
  API_URL,
  DEEP_SCAN_DETAILS_ROUTE,
  EDR_SCAN_ACTIVITY_ROUTE,
  EDR_SCAN_DETAILS_ROUTE,
  ELITE_SCAN_ACTIVITY_ROUTE,
  SYSDIAGNOSE_SCAN_ACTIVITY_ROUTE,
  SYSDIAGNOSE_SCAN_DETAILS_ROUTE,
  THREAT_HUNTER_SCAN_ACTIVITY_ROUTE,
} from '../../strings/api-consts'
import { ActivityResponse, DeepScanDetails, EdrScanDetails } from '../../types/ActivityTypes'
import { SelectOptions } from '../../types/DropDownTypes'
import { ThreatHunterUsersResponse } from '../../types/SettingsType'
import { CustomThunkDispatch } from '../../types/UserTypes'
import { setScans, setCurrentPage, setTotalPages } from './activity.slice'
import * as Sentry from '@sentry/react'

export const fetchScans =
  (pageNumber: number, pageSize: number, statusFilter: SelectOptions[], nameFilter: string, methodToShow: string) =>
  async (dispatch: CustomThunkDispatch) => {
    const scanActivityRoute = (() => {
      switch (methodToShow) {
        case 'sysdiagnose':
          return SYSDIAGNOSE_SCAN_ACTIVITY_ROUTE
        case 'threat_hunter':
          return THREAT_HUNTER_SCAN_ACTIVITY_ROUTE
        case 'edr':
          return EDR_SCAN_ACTIVITY_ROUTE
        case 'elite':
          return ELITE_SCAN_ACTIVITY_ROUTE
        default:
          return THREAT_HUNTER_SCAN_ACTIVITY_ROUTE
      }
    })()
    let url = `${API_URL + scanActivityRoute}?page=${pageNumber}&pageSize=${pageSize}`

    if (statusFilter.length === 2) {
      url += ''
    } else if (statusFilter.length > 0) {
      const severityParams = statusFilter.map((status) => `&status=${status.value}`).join('')
      url += severityParams
    } else {
      url += '&status=open'
    }

    if (nameFilter && nameFilter !== '') {
      url += `&name=${nameFilter}`
    }

    await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res: ActivityResponse) => {
        dispatch(setCurrentPage(!pageNumber ? 0 : pageNumber - 1))
        dispatch(setScans(res.scans))
        dispatch(setTotalPages(res.totalPages))
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
      })
  }

export const fetchEdrScanDetails = (scanId: string) => async () => {
  const url = API_URL + EDR_SCAN_DETAILS_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      scanId: scanId,
    }),
  })
    .then((res) => res.json())
    .then((res: EdrScanDetails) => {
      return res
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return undefined
    })
}

export const fetchDeepScanDetails =
  (scanId: string, users?: ThreatHunterUsersResponse | { error: string }) => async () => {
    const url = API_URL + DEEP_SCAN_DETAILS_ROUTE

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        scanId: scanId,
      }),
    })
      .then((res) => res.json())
      .then((res: DeepScanDetails) => {
        if (users && 'threatHunterUsers' in users) {
          const user = users.threatHunterUsers.find((user) => user.accessCode === res.accessCode)
          const scannedBy = user ? user.email : 'Unknown'
          return { ...res, scannedBy }
        } else {
          return res
        }
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
        return undefined
      })
  }

export const fetchSysdiagnoseScanDetails = (scanId: string) => async () => {
  const url = API_URL + SYSDIAGNOSE_SCAN_DETAILS_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      scanId: scanId,
    }),
  })
    .then((res) => res.json())
    .then((res: DeepScanDetails) => {
      return res
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return undefined
    })
}
