import { CheckIcon } from '@radix-ui/react-icons'
import { Avatar, Button } from '@radix-ui/themes'

interface ISsoConfirmedPageProps {
  setCurrentPage: (page: string) => void
  setShowUnenrollModal: (show: boolean) => void
}

function SsoConfirmedPage(props: ISsoConfirmedPageProps) {
  const { setCurrentPage, setShowUnenrollModal } = props

  const navigateToGroups = () => {
    localStorage.removeItem('SSO_CONFIGURED_COOKIE')
    setCurrentPage('GROUPS')
  }

  return (
    <>
      <div className="flex flex-col items-center my-20">
        <Avatar radius="full" color="green" fallback={<CheckIcon height={40} width={40} />} size="6" />
        <p className="max-w-[500px] text-center">SSO has been successfully configured.</p>
      </div>
      <div className="flex flex-col justify-center items-center">
        <Button
          className="w-[400px] h-[36px] rounded mt-10 text-white font-semibold text-sm cursor-pointer"
          onClick={() => navigateToGroups()}
        >
          <p>Add Members</p>
        </Button>
        <Button
          className="w-[400px] h-[36px] rounded mt-[10px] font-semibold text-sm cursor-pointer"
          variant="outline"
          onClick={() => {
            localStorage.removeItem('SSO_CONFIGURED_COOKIE')
            localStorage.removeItem('SSO_ONBOARDING_COOKIE')
            setShowUnenrollModal(false)
          }}
        >
          <p>Close</p>
        </Button>
      </div>
    </>
  )
}

export default SsoConfirmedPage
