import DashSideStats from './DashSideStats'
import DashAlerts from './DashAlerts'
import DashInsecureDevices from './DashInsecureDevices'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function DashboardWrapper() {
  const navigate = useNavigate()

  useEffect(() => {
    const session = localStorage.getItem('session') as string
    if (session === '{}') {
      navigate('/login')
    }
  }, [])

  return (
    <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] bg-neutral-3">
      <div className="grid grid-cols-4 h-full overflow-scroll">
        <div className="col-span-4 p-4">
          <DashSideStats />
          <DashAlerts />
          <DashInsecureDevices />
        </div>
      </div>
    </div>
  )
}

export default DashboardWrapper
