import { Cross2Icon } from '@radix-ui/react-icons'
import { AuditLog } from '../../types/SettingsType'
import { convertTimestampToIverifyDate } from '../../utils/dates'

interface IPopoutProps {
  popoutData: AuditLog
  setShowPopout: (show: boolean) => void
}

const AuditPopoutContent = (props: IPopoutProps) => {
  const { popoutData, setShowPopout } = props

  const mapCommaSeperatedValues = (key: string, value: string) => {
    const knownKeys = ['Members', 'Roles', 'Devices', 'Access Codes']
    if (knownKeys.includes(key) && value) {
      return (
        <ul className="list-disc px-8">
          {value.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )
    }
    return value
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="font-semibold border-b flex px-6 py-3 bg-neutral-2 text-lg">
          Details
          <div className="flex justify-end items-center pr-2 flex-grow">
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowPopout(false)
              }}
            >
              <Cross2Icon className="w-6 h-6" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 mb-8">
          <div className="leading-normal flex flex-col gap-2 px-8">
            <div className="font-semibold">Created Date</div>
            <div className="text-sm">{convertTimestampToIverifyDate(popoutData.createdAt, true)}</div>
          </div>
          <div className="leading-normal flex flex-col gap-2 px-8">
            <div className="font-semibold">Action By</div>
            <div className="text-sm">{popoutData.createdBy}</div>
          </div>
          <div className="leading-normal flex flex-col gap-2 px-8">
            <div className="font-semibold">Action</div>
            <div className="text-sm">{popoutData.actionDetails}</div>
          </div>
          <div className="leading-normal flex flex-col gap-2 px-8">
            <div className="font-semibold">Details</div>
            <div>
              {Object.entries(popoutData.metadata).map(([key, value]) => (
                <>
                  <p className="text-sm text-wrap" key={key}>
                    <span className="font-medium">{key}:</span>&nbsp;{mapCommaSeperatedValues(key, value)}
                  </p>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuditPopoutContent
