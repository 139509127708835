import { useEffect, useState } from 'react'
import DataGrid from '../data-grid/DataGrid'
import { useAppDispatch } from '../../reducers/store'
import { addAdmin, getAdmins, resendAdminInvite, selectSettings } from '../../reducers/settings'
import { useSelector } from 'react-redux'
import { adminColumns } from './AdminColumns'
import { Admin, addAdminInputs } from '../../types/SettingsType'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addAdminSchema } from '../../schemas/SettingsSchema'
import toast from 'react-hot-toast'
import { Button } from '@radix-ui/themes'
import DeleteAdminModal from './DeleteAdminModal'
import { SessionState } from '../../types/UserTypes'
import { Input } from '../../stories/input/Input'

function AdminSettings() {
  const { admins } = useSelector(selectSettings)
  const di = useAppDispatch()
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [showDeleteAdminModal, setShowDeleteAdminModal] = useState<boolean>(false)
  const [deleteAdminEmail, setDeleteAdminEmail] = useState<string>('')
  const currentSession = JSON.parse(localStorage.getItem('session') as string) as SessionState

  useEffect(() => {
    di(getAdmins())
  }, [])

  useEffect(() => {
    if (admins || (admins && (admins as Admin[]).length === 0)) {
      setDataLoading(false)
    } else if (!admins) {
      setDataLoading(true)
    }
  }, [admins])

  const handleResendInvite = async (adminDetails: Admin) => {
    const sent = await di(resendAdminInvite(adminDetails))
    if (!sent) {
      showToast('An error occurred while resending the invite', '❌')
    } else {
      showToast(`An invite has been resent to ${adminDetails.name} at ${adminDetails.email}`, '✅')
    }
  }

  const handleDeleteAdmin = async (email: string) => {
    if (email === currentSession.user.email) {
      showToast('You cannot remove yourself as admin', '❌')
    } else {
      setShowDeleteAdminModal(true)
      setDeleteAdminEmail(email)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<addAdminInputs>({ resolver: yupResolver(addAdminSchema) })

  const onSubmit: SubmitHandler<addAdminInputs> = async (adminDetails) => {
    const sent = await di(addAdmin(adminDetails))
    if (!sent) {
      showToast('An error occurred while adding the admin', '❌')
    } else {
      showToast(`An invite has been sent to ${adminDetails.name} at ${adminDetails.email}`, '✅')
      reset()
      di(getAdmins())
    }
  }

  const showToast = (message: string, emoji: string) =>
    toast(message, {
      duration: 6000,
      position: 'bottom-right',
      icon: emoji,
    })

  return (
    <>
      <div className="w-full h-[calc(100vh_-_80px)] pb-14 overflow-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex">
            <div className="mr-2 w-[180px]">
              <label className="text-darkGreyBlue font-semibold text-xs block">FULL NAME</label>
              <Input
                type="text"
                placeholder="Full Name"
                {...register('name', { required: true })}
                onChange={undefined}
              />
              {errors.name && <p className="text-ErrorRed">{errors.name.message}</p>}
            </div>
            <div className="w-[272px]">
              <label className="text-darkGreyBlue font-semibold text-xs block">EMAIL</label>
              <Input
                type="email"
                placeholder="name@example.com"
                {...register('email', { required: true })}
                onChange={undefined}
              />
              {errors.email && <p className="text-ErrorRed">{errors.email.message}</p>}
            </div>
            <Button type="submit" className="w-20 h-[42px] mt-4 ml-4 p-2.5 bg-PurpleBlue-10 text-white cursor-pointer">
              Add
            </Button>
          </div>
        </form>
        <p className="font-semibold text-xl mt-5">Administrators</p>
        <DataGrid columns={adminColumns(handleResendInvite, handleDeleteAdmin)} data={admins} loading={dataLoading} />
      </div>
      {showDeleteAdminModal && (
        <DeleteAdminModal setShowModal={setShowDeleteAdminModal} deleteAdminEmail={deleteAdminEmail} />
      )}
    </>
  )
}

export default AdminSettings
