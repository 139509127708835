import { CellContext, createColumnHelper } from '@tanstack/react-table'
import { DeviceInformation, ThreatFamily } from '../../types/DeviceTypes'
import { formatDistanceToNow } from 'date-fns'
import { Button, Tooltip } from '@radix-ui/themes'
import AlertIcon from '../../icons/AlertIcon'
import SheildIcon from '../../icons/SheildIcon'
import CautionIcon from '../../icons/CautionIcon'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import './styles.css'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'

export const iOsDeviceColumns = (
  checkedItems: Record<string, boolean>,
  setCheckedItems: (checkedItems: Record<string, boolean>) => void,
  handleCheckboxChange: (accessCode: string) => void,
  setShowPopout: (show: boolean) => void,
  setPopoutData: (deviceInfo: DeviceInformation) => void
) => {
  const columnHelper = createColumnHelper<DeviceInformation>()

  return [
    {
      id: 'selection',
      cell: (row: CellContext<DeviceInformation, string>) => {
        return (
          <div className="ml-6 mt-1.5">
            <input
              key={row.row.original.code}
              id={row.row.original.code}
              type="checkbox"
              className="h-4 w-4"
              checked={checkedItems[row.row.original.code] || false}
              onChange={() => {
                setCheckedItems({
                  ...checkedItems,
                  [row.row.original.code]: !checkedItems[row.row.original.code],
                })
                handleCheckboxChange(row.row.original.code)
              }}
            />
          </div>
        )
      },
    },
    columnHelper.accessor('name', {
      header: 'User',
      maxSize: 50,
      cell: (row) => {
        return (
          <div>
            <p className="whitespace-normal max-two-lines">
              {row.row.original.deviceName === null ? row.row.original.name : row.row.original.deviceName}
            </p>
            <p className="text-tableAccessGrey text-xs">{row.row.original.code}</p>
          </div>
        )
      },
    }),
    columnHelper.accessor('threats', {
      cell: (row) =>
        convertThreats(
          row.row.original.threats,
          row.row.original.latestInsecureScanDate,
          row.row.original.enrolled,
          row.row.original.secure
        ),
      header: 'Threat Detected',
    }),
    columnHelper.accessor('screenLock', {
      cell: (info) => convertBoolean(info.getValue(), info.row.original.enrolled),
      header: 'Screen Lock',
    }),
    columnHelper.accessor('biometrics', {
      cell: (info) => convertBoolean(info.getValue(), info.row.original.enrolled),
      header: 'Biometrics',
    }),
    columnHelper.accessor('isSmishingEnabled', {
      cell: (info) => convertBoolean(info.getValue() ?? false, info.row.original.enrolled),
      header: 'Smishing',
    }),
    columnHelper.accessor('version', {
      cell: (row) =>
        convertLatestIverifyVersion(
          row.row.original.version,
          row.row.original.latestAppVersion,
          row.row.original.isLatestVersion,
          row.row.original.enrolled
        ),
      header: 'iVerify Version',
    }),
    columnHelper.accessor('guidesCompleted', {
      header: 'Guides',
    }),
    columnHelper.accessor('device', {
      header: 'Device',
    }),
    columnHelper.accessor('isNotificationsEnabled', {
      cell: (info) => convertBoolean(info.getValue(), info.row.original.enrolled),
      header: 'Notifications',
    }),
    columnHelper.accessor('os', {
      cell: (row) =>
        convertLatestOs(
          row.row.original.os,
          row.row.original.osVersionState,
          row.row.original.securityPatchVersion,
          row.row.original.latestOSVersion
        ),
      header: 'OS',
    }),
    columnHelper.accessor('latestScanDate', {
      cell: (info) => convertTimestampToIverifyDate(info.getValue(), info.row.original.enrolled),
      header: 'Latest Scan',
    }),
    {
      id: 'button',
      cell: (row: { row: { original: DeviceInformation } }) => (
        <Button
          size={'2'}
          className="cursor-pointer float-right"
          onClick={() => {
            setPopoutData(row.row.original)
            setShowPopout(true)
          }}
          variant="outline"
        >
          View
        </Button>
      ),
    },
  ]
}

export const androidDeviceColumns = (
  checkedItems: Record<string, boolean>,
  setCheckedItems: (checkedItems: Record<string, boolean>) => void,
  handleCheckboxChange: (accessCode: string) => void,
  setShowPopout: (show: boolean) => void,
  setPopoutData: (deviceInfo: DeviceInformation) => void
) => {
  const columnHelper = createColumnHelper<DeviceInformation>()

  return [
    {
      id: 'selection',
      cell: (row: CellContext<DeviceInformation, string>) => {
        return (
          <div className="ml-6 mt-1.5">
            <input
              key={row.row.original.code}
              id={row.row.original.code}
              type="checkbox"
              className="h-4 w-4"
              checked={checkedItems[row.row.original.code] || false}
              onChange={() => {
                setCheckedItems({ ...checkedItems, [row.row.original.code]: !checkedItems[row.row.original.code] })
                handleCheckboxChange(row.row.original.code)
              }}
            />
          </div>
        )
      },
    },
    columnHelper.accessor('name', {
      header: 'User',
      maxSize: 50,
      cell: (row) => {
        return (
          <div>
            <p className="whitespace-normal max-two-lines">
              {row.row.original.deviceName === null ? row.row.original.name : row.row.original.deviceName}
            </p>
            <p className="text-tableAccessGrey text-xs">{row.row.original.code}</p>
          </div>
        )
      },
    }),
    columnHelper.accessor('threats', {
      cell: (row) =>
        convertThreats(
          row.row.original.threats,
          row.row.original.latestInsecureScanDate,
          row.row.original.enrolled,
          row.row.original.secure
        ),
      header: 'Threat Detected',
    }),
    columnHelper.accessor('isPlayProtectEnabled', {
      cell: (row) => convertPlayProtect(row.row.original.isPlayProtectEnabled),
      header: 'Play Protect',
    }),
    columnHelper.accessor('screenLock', {
      cell: (info) => convertBoolean(info.getValue(), info.row.original.enrolled),
      header: 'Screen Lock',
    }),
    columnHelper.accessor('biometrics', {
      cell: (info) => convertBoolean(info.getValue(), info.row.original.enrolled),
      header: 'Biometrics',
    }),
    columnHelper.accessor('version', {
      cell: (row) =>
        convertLatestIverifyVersion(
          row.row.original.version,
          row.row.original.latestAppVersion,
          row.row.original.isLatestVersion,
          row.row.original.enrolled
        ),
      header: 'iVerify Version',
    }),
    columnHelper.accessor('guidesCompleted', {
      header: 'Guides',
    }),
    columnHelper.accessor('device', {
      header: 'Device',
    }),
    columnHelper.accessor('os', {
      cell: (row) =>
        convertLatestOs(
          row.row.original.os,
          row.row.original.osVersionState,
          row.row.original.securityPatchVersion,
          row.row.original.latestOSVersion
        ),
      header: 'OS',
    }),
    columnHelper.accessor('latestScanDate', {
      cell: (info) => convertTimestampToIverifyDate(info.getValue(), info.row.original.enrolled),
      header: 'Latest Scan',
    }),
    {
      id: 'button',
      cell: (row: { row: { original: DeviceInformation } }) => (
        <Button
          size={'2'}
          className="cursor-pointer float-right"
          onClick={() => {
            setPopoutData(row.row.original)
            setShowPopout(true)
          }}
          variant="outline"
        >
          View
        </Button>
      ),
    },
  ]
}

export const convertBoolean = (value: boolean, enrolled: boolean) => {
  if (!enrolled) return null
  return value ? 'Yes' : 'No'
}

export const convertLatestIverifyVersion = (
  version: string,
  latestAppVersion: string,
  isLatestVersion: boolean,
  enrolled: boolean
) => {
  if (!enrolled) return null
  return isLatestVersion ? (
    version
  ) : (
    <Tooltip
      content={
        <>
          <p>iVerify {latestAppVersion} is available for update</p>
        </>
      }
    >
      <div className="flex">
        <AlertIcon />
        <p className="mt-0.5 ml-1">{version}</p>
      </div>
    </Tooltip>
  )
}

export const convertLatestOs = (
  os: string,
  osVersionState: string,
  securityPatchVersion: string,
  latestOSVersion: string
) => {
  if (osVersionState === 'OUT_OF_DATE') {
    return (
      <Tooltip content={<p>iOS {latestOSVersion} is available for update</p>}>
        <div className="flex">
          <AlertIcon />
          <p className="mt-0.5 ml-1">{os}</p>
        </div>
      </Tooltip>
    )
  } else if (osVersionState === 'SECURITY_PATCH_OUTDATED') {
    return (
      <Tooltip
        content={
          <p>
            Android security patch version {latestOSVersion} has been released. This device is running{' '}
            {securityPatchVersion}.
          </p>
        }
      >
        <div className="flex">
          <CautionIcon />
          <p className="mt-0.5 ml-1">{os}</p>
        </div>
      </Tooltip>
    )
  } else if (osVersionState === 'LATEST_FOR_DEVICE') {
    return (
      <Tooltip content={<p>This is an old OS version but the maximum version available for this device.</p>}>
        <div className="flex">
          <CautionIcon />
          <p className="mt-0.5 ml-1">{os}</p>
        </div>
      </Tooltip>
    )
  } else {
    return os
  }
}

export const convertThreats = (
  threats: ThreatFamily[],
  latestInsecureScanDate: string,
  enrolled: boolean,
  secure: boolean
) => {
  // if (secure && !hasSecureScan) {
  //   return (
  //     <Tooltip content={<p>The integrity of the device is unknown as there are no secure scans</p>}>
  //       <div className="flex">
  //         <QuestionMarkCircledIcon className="mt-1" />
  //         <p className="mt-0.5 ml-1">Unknown</p>
  //       </div>
  //     </Tooltip>
  //   )
  // } else
  if (threats && threats.length > 0) {
    const date = new Date(latestInsecureScanDate)
    const timeSince = formatDistanceToNow(date, { addSuffix: true })
    return (
      <Tooltip
        content={
          <>
            <p>Threats detected {timeSince}: </p>
            {threats.map((threat) => (
              <p key={threat.code}> • {threat.description}</p>
            ))}
          </>
        }
      >
        <div className="flex">
          <AlertIcon />
          <p className="mt-0.5 ml-1">Yes</p>
        </div>
      </Tooltip>
    )
  } else if (enrolled && secure === null) {
    return (
      <Tooltip content="The last scan was not completed">
        <div className="flex">
          <QuestionMarkCircledIcon className="mt-1" />
          <p className="mt-0.5 ml-1">Unknown</p>
        </div>
      </Tooltip>
    )
  } else if (!enrolled) {
    return 'Not enrolled'
  } else {
    return (
      <div className="flex">
        <SheildIcon />
        <p className="mt-0.5 ml-1">No</p>
      </div>
    )
  }
}

export const convertPlayProtect = (isPlayProtectEnabled: boolean) => {
  if (isPlayProtectEnabled === false) {
    return (
      <div className="flex">
        <AlertIcon />
        <p className="mt-0.5 ml-1">Disabled</p>
      </div>
    )
  } else {
    return (
      <div className="flex">
        <SheildIcon />
        <p className="mt-0.5 ml-1">Enabled</p>
      </div>
    )
  }
}
