import Header from './components/header/Header'
import SideBar from './components/sidebar/SideBar'
import { Flex, Grid } from '@radix-ui/themes'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser, setCurrentPage, setSession } from './reducers/users'
import Activity from './components/activity/ActivityWrapper'
import DashboardWrapper from './components/dashboard/DashboardWrapper'
import AlertsWrapper from './components/alert-console/AlertsWrapper'
import DevicesWrapper from './components/devices/DevicesWrapper'
import MembersWrapper from './components/members/MembersWrapper'
import { useEffect } from 'react'
import SsoErrorWrapper from './components/sso-error/SsoErrorWrapper'
import { useNavigate } from 'react-router-dom'
import { SessionState } from './types/UserTypes'
import DomainBlocklistWrapper from './components/domain-blocklist/DomainBlocklistWrapper'

function App() {
  const { session, currentPage } = useSelector(selectUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const goToSSO = localStorage.getItem('SSO_ONBOARDING_COOKIE')
    const goToConfigured = localStorage.getItem('SSO_CONFIGURED_COOKIE')
    const storedCurrentPage = localStorage.getItem('DASHBOARD_PAGE_COOKIE')
    const storedSettingsPage = localStorage.getItem('SETTINGS_PAGE_COOKIE')

    if (storedSettingsPage) {
      navigate('/settings')
    }

    if (storedCurrentPage) {
      dispatch(setCurrentPage(storedCurrentPage))
    }

    if (goToSSO === 'true' || goToConfigured === 'true') {
      dispatch(setCurrentPage('MEMBERS'))
    }
    if (window.location.search.includes('error=sso-unauthorized')) {
      dispatch(setCurrentPage('SSO_ERROR'))
    }
  }, [])

  useEffect(() => {
    const storedSession = localStorage.getItem('session') as string

    if (currentPage === 'SSO_ERROR') {
      localStorage.setItem('session', '{}')
      localStorage.removeItem('SSO_CONFIGURED_COOKIE')
      localStorage.removeItem('SSO_ONBOARDING_COOKIE')
      dispatch(setSession(undefined))
    } else if (!session && storedSession === '{}' && currentPage !== 'SSO_ERROR') {
      localStorage.setItem('session', '{}')
      localStorage.removeItem('SSO_CONFIGURED_COOKIE')
      localStorage.removeItem('SSO_ONBOARDING_COOKIE')
      dispatch(setCurrentPage('DASHBOARD'))
      dispatch(setSession(undefined))
      navigate('/login')
    } else if (!session && storedSession !== '{}') {
      dispatch(setSession(JSON.parse(storedSession) as SessionState))
    }
  }, [session])

  useEffect(() => {
    if (import.meta.env.VITE_PRETTY_ENV !== 'Production') {
      document.title = '[' + import.meta.env.VITE_PRETTY_ENV + '] ' + 'iVerify EDR Portal'
      const scriptContent = `
        (function(apiKey){
          (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
          v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
              o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
              y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
              z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
              pendo.initialize({
                  visitor: {
                      id: '1'
                  },
              });
        })('83cdb849-6d49-43ac-7248-a144aa26be64');
      `
      const script = document.createElement('script')
      script.textContent = scriptContent
      document.body.appendChild(script)
    }
  }, [])

  const switchMainScreen = () => {
    switch (currentPage) {
      case 'DASHBOARD':
        return <DashboardWrapper />
      case 'ALERTS':
        return <AlertsWrapper />
      case 'ACTIVITY':
        return <Activity />
      case 'DEVICES':
        return <DevicesWrapper />
      case 'MEMBERS':
        return <MembersWrapper />
      case 'BLOCKLIST':
        return <DomainBlocklistWrapper />
      case 'SSO_ERROR':
        return <SsoErrorWrapper />
      default:
        return <DashboardWrapper />
    }
  }

  return (
    <div className="light flex">
      <Header />
      <Grid columns="2" gap="3" className="mt-[74px]">
        <Flex direction="column">
          <SideBar />
        </Flex>

        {/* MAIN SCREEN */}
        <Flex direction="column">{currentPage && switchMainScreen()}</Flex>
      </Grid>
    </div>
  )
}

export default App
