import { useEffect, useState } from 'react'
import DataGrid from '../data-grid/DataGrid.tsx'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../strings/api-consts.ts'
import { emailMembers, fetchDevices, unenrollDevices } from '../../reducers/devices/devices.thunk.ts'
import { useAppDispatch } from '../../reducers/store.ts'
import { selectDevices, setDevices } from '../../reducers/devices/devices.slice.ts'
import { useDispatch, useSelector } from 'react-redux'
import { DeviceInformation } from '../../types/DeviceTypes.ts'
import { CrossCircledIcon, MagnifyingGlassIcon, PlusCircledIcon } from '@radix-ui/react-icons'
import { Button, IconButton, SegmentedControl, TextField } from '@radix-ui/themes'
import DropDown from '../dropdown/DropDown.tsx'
import { severityOptions, UNENROLL, INVITATION, actionOptions } from '../../strings/dropdown-options.ts'
import { SelectOptions } from '../../types/DropDownTypes.ts'
import toast from 'react-hot-toast'
import { iOsDeviceColumns, androidDeviceColumns } from './DeviceColumns.tsx'
import Popout from '../popout/Popout.tsx'
import AddDeviceModal from './AddDeviceModal.tsx'
import { useNavigate } from 'react-router-dom'
import DeviceUnenrollModal from './DeviceUnenrollModal.tsx'
import { SelectOption } from '../../types/MemberTypes.ts'
import checkIcon from '../../assets/check_circle.svg'
import PageSkeleton from '../skeleton/PageSkeleton.tsx'
import { Pagination } from '../pagination/Pagination.tsx'

function DevicesWrapper() {
  const di = useAppDispatch()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { devices, totalPages, currentPage } = useSelector(selectDevices)
  const [severityFilter, setSeverityFilter] = useState<SelectOptions[]>([])
  const [alertOption, setAlertOption] = useState<SelectOptions | null>(null)
  const [clickedInputs, setClickedInputs] = useState<DeviceInformation[]>([])
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({})
  const [showUnenrollModal, setShowUnenrollModal] = useState<boolean>(false)
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [platformToShow, setPlatformToShow] = useState<string>('ios')
  const [showPopout, setShowPopout] = useState<boolean>(false)
  const [popoutData, setPopoutData] = useState<DeviceInformation | null>(null)
  const [showAddDeviceModal, setShowAddDeviceModal] = useState<boolean>(false)
  const [nameFilter, setNameFilter] = useState<string>('')

  useEffect(() => {
    setDataLoading(true)
    di(fetchDevices(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, severityFilter, platformToShow, nameFilter))
  }, [severityFilter, platformToShow])

  const handlePageClick = (data: SelectOption) => {
    setDataLoading(true)
    di(fetchDevices(data.selected + 1, DEFAULT_PAGE_SIZE, severityFilter, platformToShow, nameFilter))
  }

  const handleCheckboxChange = (accessCode: string) => {
    const selectedItem = devices!.find((item) => item.code === accessCode)!
    if (clickedInputs.includes(selectedItem)) {
      setClickedInputs(clickedInputs.filter((item) => item.code !== accessCode))
    } else {
      setClickedInputs([...clickedInputs, selectedItem])
    }
  }

  const handleAction = async () => {
    const deviceCodes = clickedInputs.map((row: DeviceInformation) => row.code)
    if (alertOption!.value === INVITATION) {
      const count = await di(emailMembers(deviceCodes))
      count
        ? showToast(`Invitations are being sent to ${count} organization member${count === '1' ? '' : 's'}`, '✅')
        : resetTable()
      resetTable()
    }
    if (alertOption!.value === UNENROLL) {
      setShowUnenrollModal(true)
    }
  }

  const resetTable = () => {
    setCheckedItems({})
    setClickedInputs([])
    setAlertOption(null)
  }

  const showToast = (message: string, emoji: string) =>
    toast(message, {
      duration: 6000,
      position: 'bottom-right',
      icon: emoji,
    })

  const unenrollUserDevices = async () => {
    const deviceCodes = clickedInputs.map((row: DeviceInformation) => row.code)
    await di(unenrollDevices(deviceCodes))
    await di(fetchDevices(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, severityFilter, platformToShow, nameFilter)).then(() => {
    showToast(
      `${deviceCodes.length} device${deviceCodes.length === 1 ? ' was' : 's were'} successfully unenrolled`,
      '✅'
    )
      setShowUnenrollModal(false)
    })
  }

  useEffect(() => {
    if (devices || (devices && (devices as DeviceInformation[]).length === 0)) {
      setDataLoading(false)
    } else if (!devices) {
      setDataLoading(true)
    }
  }, [devices])

  useEffect(() => {
    const session = localStorage.getItem('session') as string
    if (session === '{}') {
      navigate('/login')
    }
    return () => {
      dispatch(setDevices(undefined))
    }
  }, [])

  const handleReloadingDevicesAfterUpdate = () => {
    setDataLoading(true)
    di(fetchDevices(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, severityFilter, platformToShow, nameFilter))
  }

  const searchName = () => {
    setDataLoading(true)
    di(fetchDevices(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, severityFilter, platformToShow, nameFilter))
  }

  const clearNameFilter = () => {
    setDataLoading(true)
    di(fetchDevices(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, severityFilter, platformToShow, ''))
  }

  return (
    <>
      <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l bg-neutral-3 w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] p-9">
        {dataLoading && (
          <div className="flex justify-center items-center min-h-screen">
            <PageSkeleton />
          </div>
        )}
        <div className="mb-6 flex w-full">
          <SegmentedControl.Root
            defaultValue="ios"
            value={platformToShow}
            className="h-[38px] mr-4 bg-[#00003B0D] text-gray-600"
            onValueChange={(newValue: string) => setPlatformToShow(newValue)}
          >
            <SegmentedControl.Item value="ios">iOS</SegmentedControl.Item>
            <SegmentedControl.Item value="android">Android</SegmentedControl.Item>
            <SegmentedControl.Item value="unenrolled">Unenrolled</SegmentedControl.Item>
          </SegmentedControl.Root>
          <TextField.Root
            value={nameFilter}
            placeholder="Search..."
            className="w-[200px] h-[38px] mr-4"
            onChange={(e) => {
              setNameFilter(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                searchName()
              }
            }}
          >
            <TextField.Slot className="pl-0"></TextField.Slot>
            {nameFilter !== '' && (
              <TextField.Slot>
                <IconButton
                  size="1"
                  variant="ghost"
                  onClick={() => {
                    setNameFilter('')
                    clearNameFilter()
                  }}
                >
                  <CrossCircledIcon height="14" width="14" />
                </IconButton>
              </TextField.Slot>
            )}
            <TextField.Slot>
              <IconButton size="1" variant="ghost" onClick={() => searchName()}>
                <MagnifyingGlassIcon height="14" width="14" />
              </IconButton>
            </TextField.Slot>
          </TextField.Root>
          <DropDown
            value={severityFilter}
            onChangeHandler={setSeverityFilter}
            options={severityOptions}
            style="z-10 min-w-[160px]"
            placeholder="Severity"
            multiple={true}
            disabled={false}
          />
          <div className="ml-auto flex">
            <DropDown
              value={alertOption}
              onChangeHandler={setAlertOption}
              options={actionOptions}
              style="z-10 max-w-[300px]"
              placeholder={clickedInputs.length === 0 ? 'Select devices' : 'Select Option'}
              multiple={false}
              disabled={clickedInputs.length === 0 ? true : false}
            />
            {clickedInputs.length > 0 && (
              <Button
                onClick={handleAction}
                color="iris"
                style={{ height: '38px', marginLeft: '8px' }}
                variant="outline"
                disabled={!alertOption}
              >
                Send
              </Button>
            )}
            <Button
              className="bg-PurpleBlue-10 h-[38px] ml-4 cursor-pointer"
              onClick={() => setShowAddDeviceModal(true)}
            >
              <PlusCircledIcon /> Add Devices
            </Button>
          </div>
        </div>
        {devices && devices.length > 0 && (
          <>
            <DataGrid
              columns={
                platformToShow === 'android'
                  ? androidDeviceColumns(
                      checkedItems,
                      setCheckedItems,
                      handleCheckboxChange,
                      setShowPopout,
                      setPopoutData
                    )
                  : iOsDeviceColumns(checkedItems, setCheckedItems, handleCheckboxChange, setShowPopout, setPopoutData)
              }
              data={devices}
              loading={dataLoading}
            />
            <div className="mt-2">
              <Pagination totalPages={totalPages} forcePage={currentPage} handlePageClick={handlePageClick} />
            </div>
          </>
        )}
        {devices && devices.length === 0 && (
          <div className="block items-center mt-20">
            <div className="flex items-center justify-center">
              <img src={checkIcon} alt="No devices found" className="h-12 w-12" />
            </div>
            <p className="text-center font-bold">No Devices Found</p>
            <p className="text-center text-sm">Add devices above or refine your search</p>
          </div>
        )}
      </div>
      {showUnenrollModal && (
        <DeviceUnenrollModal
          clickedInputs={clickedInputs}
          setShowUnenrollModal={setShowUnenrollModal}
          unenrollUserDevices={unenrollUserDevices}
        />
      )}
      {showPopout && (
        <Popout
          setShowPopout={setShowPopout}
          contentScreen="DEVICE"
          popoutData={popoutData}
          setClickedInputs={setClickedInputs}
          setShowUnenrollModal={setShowUnenrollModal}
          handleReloadingDevicesAfterUpdate={handleReloadingDevicesAfterUpdate}
        />
      )}
      {showAddDeviceModal && (
        <AddDeviceModal setShowAddDeviceModal={setShowAddDeviceModal} setPlatformToShow={setPlatformToShow} />
      )}
    </>
  )
}

export default DevicesWrapper
