import { useSelector } from 'react-redux'
import { Button, IconButton } from '@radix-ui/themes'
import { useEffect, useState } from 'react'
import { getMdmStatus, selectSettings, updateMdmStatus } from '../../reducers/settings'
import { useAppDispatch } from '../../reducers/store'
import toast from 'react-hot-toast'
import { CopyIcon, EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import DisconnectModal from './DisconnectModal'
import ContentSkeleton from '../skeleton/ContentSkeleton'

function MdmSettings() {
  const { mdm } = useSelector(selectSettings)
  const [showMdmModal, setShowMdmModal] = useState<boolean>(false)
  const [showMdmToken, setShowMdmToken] = useState<boolean>(false)
  const di = useAppDispatch()

  useEffect(() => {
    di(getMdmStatus())
  }, [])

  const hasMdm = (
    <>
      <label className="text-darkGreyBlue font-semibold text-xs block">MDM TOKEN</label>
      <div className="h-10 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm mb-6 max-w-[500px]">
        {showMdmToken ? mdm?.token : '*********************'}
        <div className="flex mt-0.5">
          <IconButton size="2" variant="ghost" className="mr-1" onClick={() => setShowMdmToken(!showMdmToken)}>
            {showMdmToken ? <EyeNoneIcon height="16" width="16" /> : <EyeOpenIcon height="16" width="16" />}
          </IconButton>
          <IconButton
            size="2"
            variant="ghost"
            onClick={() => {
              navigator.clipboard.writeText(mdm!.token)
              toast.success('MDM Token copied to clipboard', { duration: 6000 })
            }}
          >
            <CopyIcon height="16" width="16" />
          </IconButton>
        </div>
      </div>
      <Button className="bg-ErrorRed mt-4 w-[500px] cursor-pointer" onClick={() => setShowMdmModal(true)}>
        Disconnect MDM
      </Button>
      <div className="max-w-[500px] mt-8">
        <p className="text-sm mx-auto text-center ">Need help?</p>
        <p className="text-sm text-center">
          Please reference{' '}
          <a href="https://docs.iverify.io/mdm" target="_blank" className="text-PurpleBlue-10 font-semibold">
            MDM Setup Documentation
          </a>
        </p>
      </div>
    </>
  )

  const mdmNotConfiguredBody = (
    <>
      <div className="italic">Not yet configured</div>
      <Button className="mt-4 bg-PurpleBlue-10 cursor-pointer" onClick={() => di(updateMdmStatus(true))}>
        Enable MDM
      </Button>
      <div className="max-w-[500px] mt-8">
        <p className="text-sm mx-auto text-center ">Need help?</p>
        <p className="text-sm text-center">
          Please reference{' '}
          <a href="https://docs.iverify.io/mdm" target="_blank" className="text-PurpleBlue-10 font-semibold">
            MDM Setup Documentation
          </a>
        </p>
      </div>
    </>
  )

  const mdmTabBody = mdm?.enabled ? hasMdm : mdmNotConfiguredBody

  return (
    <>
      <div className="w-full h-full">
        {!mdm && <ContentSkeleton />}
        {mdm && mdmTabBody}
      </div>
      {showMdmModal && <DisconnectModal setShowModal={setShowMdmModal} screen={'MDM'} />}
    </>
  )
}

export default MdmSettings
