import { createColumnHelper } from '@tanstack/react-table'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { Button } from '@radix-ui/themes'
import { BlocklistLog } from '../../types/BlocklistTypes'

export const domainBlocklistLogsColumns = (
  setShowPopout: (show: boolean) => void,
  setPopoutData: (memberInfo: BlocklistLog) => void
) => {
  const columnHelper = createColumnHelper<BlocklistLog>()

  const columnHelpers = [
    columnHelper.accessor('domain', {
      header: 'Domain',
    }),
    columnHelper.accessor('timesBlocked', {
      header: 'Blocked count',
    }),
    columnHelper.accessor('lastOccurredAt', {
      cell: (info) => convertTimestampToIverifyDate(info.getValue(), true),
      header: 'Last blocked',
    }),

    {
      id: 'button',
      cell: (row: { row: { original: BlocklistLog } }) => (
        <Button
          size={'2'}
          className="cursor-pointer float-right"
          onClick={() => {
            setPopoutData(row.row.original)
            setShowPopout(true)
          }}
          variant="outline"
        >
          View
        </Button>
      ),
    },
  ]

  return columnHelpers
}
