import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table'
import { Member, MemberDevice } from '../../types/MemberTypes'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { Button } from '@radix-ui/themes'

export const memberColumns = (
  setShowPopout: (show: boolean) => void,
  setPopoutData: (memberInfo: Member) => void,
  showConditionalAccess?: boolean
) => {
  const columnHelper = createColumnHelper<Member>()

  const getDeviceCount = (devices: MemberDevice[]) => {
    return devices.length
  }

  const generateCAStatus = (
    isBlocked?: boolean,
    failedToBlock?: boolean,
    gracePeriod?: string,
    blockedByPolicy?: string
  ) => {
    const now = new Date()
    const gracePeriodDate = new Date(gracePeriod ?? '')
    if (isBlocked && now < gracePeriodDate && !blockedByPolicy?.includes('AlertSeverity')) {
      return <p className="text-[#D6A35C]">In Grace Period</p>
    } else if (failedToBlock) {
      return <p className="text-[#d93d42]">Failed to Blocked</p>
    } else if (isBlocked) {
      return <p className="text-[#d93d42]">Blocked</p>
    } else {
      return <p className="text-black">Active</p>
    }
  }

  const columnHelpers = [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('email', {
      header: 'Email',
    }),
    columnHelper.accessor('devices', {
      cell: (info) => getDeviceCount(info.getValue()),
      header: 'Devices',
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => convertTimestampToIverifyDate(info.getValue(), true),
      header: 'Added',
    }),
    {
      id: 'button',
      cell: (row: { row: { original: Member } }) => (
        <Button
          size={'2'}
          className="cursor-pointer float-right"
          onClick={() => {
            setPopoutData(row.row.original)
            setShowPopout(true)
          }}
          variant="outline"
        >
          View
        </Button>
      ),
    },
  ]

  if (showConditionalAccess) {
    const conditionalAccessColumn = columnHelper.accessor('isBlocked', {
      header: 'Conditional Access',
      cell: (row) => {
        return generateCAStatus(
          row.row.original.isBlocked,
          row.row.original.failedToBlock,
          row.row.original.gracePeriod,
          row.row.original.blockedByPolicy
        )
      },
    }) as AccessorKeyColumnDef<Member, string>
    columnHelpers.splice(1, 0, conditionalAccessColumn)
  }

  return columnHelpers
}
