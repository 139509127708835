import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { loginUser, lookupOrganization, selectUser, setLoginPage } from '../../reducers/users'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@radix-ui/themes'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { Input } from '../../stories/input/Input'

function LoginForm() {
  const [validEmail, setValidEmail] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const dispatch = useDispatch()
  const di = useAppDispatch()
  const navigate = useNavigate()
  const { hasOrgSso } = useSelector(selectUser)

  const handleLogin = async () => {
    if (password === '') {
      toast.error('Please enter your password')
      return
    } else {
      const loggedInUser = await di(loginUser(email, password))
      loggedInUser.error
        ? toast.error(loggedInUser.error, {
            duration: 6000,
          })
        : navigate('/')
    }
  }

  const lookupByEmail = async () => {
    if (email === '' || !validEmail) {
      toast.error('Please enter a valid email address')
    } else {
      await di(lookupOrganization(email))
    }
  }

  useEffect(() => {
    const valid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    valid ? setValidEmail(true) : setValidEmail(false)
  }, [email])

  const handleButtonLogic = () => {
    hasOrgSso === undefined ? lookupByEmail() : handleLogin()
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleButtonLogic()
      }}
      className="w-[80%] lg:w-[70%] 2xl:w-[60%] max-w-[460px]"
    >
      <p className="text-black text-3xl text-left mb-6">Sign in to iVerify EDR</p>
      <label className="text-black w-full">Email Address</label>
      <div className="mt-2">
        <Input value={email} type="email" placeholder="name@example.com" onChange={setEmail} />
      </div>
      {hasOrgSso === false && (
        <>
          <label className="text-black w-full block mt-6">Password</label>
          <div className="mt-2">
            <Input value={password} type="password" placeholder="password" onChange={setPassword} />
          </div>
        </>
      )}

      <Button className="w-full rounded mt-6 cursor-pointer bg-PurpleBlue-9 hover:bg-PurpleBlue-10 active:bg-PurpleBlue-10">
        {hasOrgSso === undefined ? 'Continue' : 'Sign In'}
      </Button>

      {hasOrgSso === false && (
        <Button
          onClick={() => dispatch(setLoginPage('FORGOT'))}
          className="text-PurpleBlue-10 mt-4 cursor-pointer text-center w-[96%] ml-0"
          variant="ghost"
        >
          Forgotten Password
        </Button>
      )}
    </form>
  )
}

export default LoginForm
