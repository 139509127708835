import ReactDOM, { Root } from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { Theme } from '@radix-ui/themes'
import '@radix-ui/themes/styles.css'
import { Provider } from 'react-redux'
import { store, useAppDispatch } from './reducers/store.ts'
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom'
import LoginWrapper from './components/login/LoginWrapper'
import { LOGIN_ROUTE } from './strings/api-consts.ts'
import { getUserSession } from './reducers/users/users.thunk.ts'
import SettingsWrapper from './components/settings/SettingsWrapper.tsx'
import SessionHydrater from './components/login/SessionHydrater.tsx'
import AdminPassword from './components/admin-password/AdminPassword.tsx'
import { Toaster } from 'react-hot-toast'
import { SessionState } from './types/UserTypes.ts'
import { datadogRum } from '@datadog/browser-rum'
import ErrorWrapper from './components/error/ErrorWrapper.tsx'
import * as Sentry from '@sentry/react'

if (import.meta.env.VITE_DATADOG_ENVIRONMENT !== 'local') {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_KEY,
    site: 'datadoghq.com',
    service: import.meta.env.VITE_DATADOG_SERVICE,
    env: import.meta.env.VITE_DATADOG_ENVIRONMENT,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
  })

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}

if (import.meta.env.VITE_PRETTY_ENV !== 'Production') {
  document.title = '[' + import.meta.env.VITE_PRETTY_ENV + '] ' + 'iVerify EDR Portal'
} else {
  document.title = 'iVerify EDR Portal'
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoute></PrivateRoute>,
    children: [
      {
        path: '/',
        element: <App />,
      },
      {
        path: '/settings',
        element: <SettingsWrapper />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginWrapper />,
  },
  {
    path: '/sso-login',
    element: <SessionHydrater />,
  },
  {
    path: '/update-password/:token',
    element: <AdminPassword />,
  },
  {
    path: '/error',
    element: <ErrorWrapper />,
  },
])

// Check if root is already created to avoid duplicate createRoot calls
const rootElement = document.getElementById('root') as HTMLElement
const existingRoot = (rootElement as { _reactRootContainer?: Root })._reactRootContainer
if (!existingRoot) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <Provider store={store}>
      <Theme scaling="100%" accentColor="violet">
        <RouterProvider router={router} />
        <Toaster
          toastOptions={{
            style: {
              maxWidth: 500,
            },
          }}
        />
      </Theme>
    </Provider>
  )
} else {
  existingRoot.render(
    <Provider store={store}>
      <Theme scaling="100%">
        <RouterProvider router={router} />
        <Toaster
          toastOptions={{
            style: {
              maxWidth: 500,
            },
          }}
        />
      </Theme>
    </Provider>
  )
}
// eslint-disable-next-line react-refresh/only-export-components
function PrivateRoute() {
  const di = useAppDispatch()
  di(getUserSession())
  const session = JSON.parse(localStorage.getItem('session') as string) as SessionState
  const isAuthenticated = session && session?.user?.email
  return isAuthenticated ? <Outlet /> : <Navigate to={LOGIN_ROUTE} />
}
