const SheildIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6932 4.06562C11.8884 3.97813 12.1116 3.97813 12.3068 4.06562L19.5568 7.31562C19.81 7.42914 19.9799 7.67321 19.9983 7.95011L19.25 8C19.9983 7.95011 19.9983 7.94977 19.9983 7.95011L19.9986 7.95425L19.9991 7.96247L20.0008 7.99094C20.0021 8.01517 20.0039 8.0498 20.0058 8.09415C20.0096 8.18283 20.014 8.31046 20.0165 8.4715C20.0216 8.79335 20.0195 9.24992 19.9912 9.79665C19.9347 10.8858 19.773 12.3534 19.3455 13.8332C18.9195 15.3078 18.2149 16.8458 17.0381 18.0225C15.8419 19.2188 14.1976 20 12 20C9.80236 20 8.1581 19.2188 6.96187 18.0225C5.78514 16.8458 5.08048 15.3078 4.65448 13.8332C4.22699 12.3534 4.06531 10.8858 4.00884 9.79665C3.98049 9.24992 3.97841 8.79335 3.98351 8.4715C3.98607 8.31046 3.99043 8.18283 3.99424 8.09415C3.99615 8.0498 3.99792 8.01517 3.99925 7.99094L4.0009 7.96247L4.00141 7.95425L4.00158 7.95166C4.0016 7.95132 4.00168 7.95011 4.75002 8L4.00168 7.95011C4.02014 7.67321 4.18999 7.42914 4.44323 7.31562L11.6932 4.06562ZM5.48336 8.49317C5.48335 8.49388 5.48334 8.49459 5.48333 8.4953C5.47867 8.78868 5.48049 9.21101 5.50683 9.71898C5.55973 10.7392 5.71055 12.0841 6.09555 13.4168C6.48206 14.7547 7.08989 16.0292 8.02253 16.9619C8.93567 17.875 10.1977 18.5 12 18.5C13.8024 18.5 15.0643 17.875 15.9775 16.9619C16.9101 16.0292 17.518 14.7547 17.9045 13.4168C18.2895 12.0841 18.4403 10.7392 18.4932 9.71898C18.5195 9.21101 18.5214 8.78868 18.5167 8.4953C18.5167 8.49459 18.5167 8.49388 18.5167 8.49317L12 5.57191L5.48336 8.49317Z"
        fill="#31DA23"
      />
    </svg>
  )
}

export default SheildIcon
