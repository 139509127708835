import * as yup from 'yup'
import { EMAIL_REGEX } from '../strings/yup-regex'

export const addAdminSchema = yup.object().shape({
  name: yup.string().required('Full name is a required'),
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email address').required('Email is a required field'),
})

export const addSSOMemberSchema = yup.object().shape({
  clientId: yup.string().required('Client ID is required'),
  clientSecret: yup.string().required('Client Secret is required'),
  tenant: yup.string().required('Tenant is required'),
})

export const enrollEmailTemplateSchema = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  body: yup.string().max(3000, 'Email body cannot exceed 3000 characters'),
})

export const addConditionalAccessSchema = yup.object().shape({
  tenant: yup.string().required('Tenant is required'),
  clientId: yup.string().required('Client ID is required'),
  clientSecret: yup.string().required('Client Secret is required'),
  userExtension: yup.string().required('User Extension is required'),
  groupId: yup.string().required('Group ID is required'),
  provider: yup.string().required('Provider is required'),
})

export const addDomainSchema = yup.object().shape({
  name: yup.string().required('Full name is required'),
  domain: yup
    .string()
    .matches(
      /^(?!www\.)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
      'Please enter a valid url'
    )
    .required('Domain is required'),
})
