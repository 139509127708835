import { createColumnHelper } from '@tanstack/react-table'
import { ThreatHunterUser } from '../../types/SettingsType'

export const threatHunterColumns = () => {
  const columnHelper = createColumnHelper<ThreatHunterUser>()

  return [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('email', {
      header: 'Email',
    }),
    columnHelper.accessor('accessCode', {
      header: 'Access Code',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
    }),
  ]
}
