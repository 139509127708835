import { Cross2Icon } from '@radix-ui/react-icons'
import { Avatar, Button } from '@radix-ui/themes'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

function ErrorWrapper() {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const type = params.get('type')
  const [errorText, setErrorText] = useState<string>('')

  useEffect(() => {
    switch (type) {
      case 'oauth-access-denied':
        setErrorText('The SSO provider denied the request')
        break
      case 'oauth-server-error':
        setErrorText('The SSO provider server is temporarily unavailable.')
        break
      case 'oauth-unavailable':
        setErrorText('Failed to authenticate with your identity provider')
        break
      case 'sso-oauth-error':
        setErrorText('The SSO provider responded with an error')
        break
      default:
        setErrorText('The SSO provider responded with an error')
    }
  }, [type])

  return (
    <div className="light flex">
      <div className="absolute top-0 left-0 rounded-tl-lg border-t border-l w-full h-full bg-neutral-3 p-9">
        <div className="flex flex-col items-center mt-20">
          <Avatar radius="full" color="red" fallback={<Cross2Icon height={40} width={40} />} size="6" />
          <p className="max-w-[500px] text-center font-bold text-PurpleBlue-10 text-2xl mt-6">An error has occurred</p>
          <p className="max-w-[500px] text-center font-bold text-lg mt-2">{errorText}</p>
          <p className="max-w-[500px] text-center mt-4">Please contact your organization administrator.</p>
          <p className="max-w-[500px] text-center">
            You can also refer to our{' '}
            <a
              href="https://iverify.io/frequently-asked-questions"
              target="_blank"
              className="text-PurpleBlue-10 font-semibold"
            >
              FAQs
            </a>{' '}
            or contact us at{' '}
            <a href="mailto:support@iverify.io" target="_blank" className="text-PurpleBlue-10 font-semibold">
              support@iverify.io
            </a>
          </p>
          <Button className="h-[38px] bg-PurpleBlue-10 cursor-pointer mt-4" onClick={() => navigate('/')}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorWrapper
