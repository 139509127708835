import { Alert } from '../../types/AlertTypes'
import { createColumnHelper } from '@tanstack/react-table'
import { generateMethodView, generateSeverityView } from '../alert-console/AlertColumns'

export const dashAlertColumns = () => {
  const columnHelper = createColumnHelper<Alert>()

  return [
    columnHelper.accessor('alertName', {
      header: 'Name',
      maxSize: 100,
    }),
    columnHelper.accessor('severity', {
      header: 'Severity',
      cell: (row) => {
        return generateSeverityView(row.row.original.severity)
      },
    }),
    columnHelper.accessor('memberName', {
      header: 'Member',
      cell: (row) => {
        return row.row.original.memberName || 'Unknown'
      },
    }),
    columnHelper.accessor('device', {
      header: 'Device',
      cell: (row) => {
        return row.row.original.device !== 'null' ? row.row.original.device : 'Unknown'
      },
    }),
    columnHelper.accessor('scanMethod', {
      header: 'Scan Method',
      cell: (row) => {
        return generateMethodView(row.row.original.scanMethod)
      },
    }),
  ]
}
