import { useDropzone } from 'react-dropzone'

interface IDropZoneProps {
  uploadedFiles: File[]
  setUploadedFiles: (files: File[]) => void
}

const DropZone = (props: IDropZoneProps) => {
  const { uploadedFiles, setUploadedFiles } = props
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'text/csv': [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles.map((file) => file))
    },
  })
  const borderColor = isDragActive ? 'border-purple-500' : 'border-gray-300'
  return (
    <>
      <div
        {...getRootProps()}
        className={`w-[400px] h-[200px] rounded-xl mt-8 mx-auto border-2 ${borderColor} border-dashed`}
      >
        <input {...getInputProps()} />
        <div className="w-[400px] h-[200px] flex flex-col justify-center items-center">
          {!isDragActive ? (
            <>
              <p>Drag and Drop Here</p>
              <p>- or -</p>
              <p className="font-semibold text-PurpleBlue-10 cursor-pointer">Browse Files</p>
            </>
          ) : (
            <p className="font-semibold text-PurpleBlue-10">Drop the File Here</p>
          )}
        </div>
      </div>
      <ul>
        {uploadedFiles.map((file) => (
          <li key={file.name} className="mt-4 font-semibold text-PurpleBlue-10 flex justify-center">
            {file.name}
          </li>
        ))}
      </ul>
    </>
  )
}

export default DropZone
