/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface MemberState {
  // TODO: Define the structure of the members state
  members: any[] | undefined
  totalPages: number
  currentPage: number
}

const initialState: MemberState = {
  members: undefined,
  totalPages: 1,
  currentPage: 0,
}

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setMembers(state, action: PayloadAction<any>) {
      state.members = action.payload
    },
    setTotalPages(state, action: PayloadAction<any>) {
      state.totalPages = action.payload
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload
    },
  },
})
export const selectMembers = (state: RootState) => state.members

export const { setMembers, setTotalPages, setCurrentPage } = membersSlice.actions
export default membersSlice.reducer
