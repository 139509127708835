import { useDispatch, useSelector } from 'react-redux'
import { fetchAlerts, selectAlerts } from '../../reducers/alerts'
import { dashAlertColumns } from './DashAlertColumns'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { DASH_PAGE_SIZE, DEFAULT_PAGE } from '../../strings/api-consts'
import { Button, Skeleton } from '@radix-ui/themes'
import { setCurrentPage } from '../../reducers/users'
import DashboardDataGrid from '../data-grid/DashboardDataGrid'
import checkIcon from '../../assets/check_circle.svg'
import { ThreatHunterUsersResponse } from '../../types/SettingsType'
import { getThreatHunterUsers } from '../../reducers/settings'
import { alertOptions } from '../../strings/dropdown-options'

function DashAlerts() {
  const di = useAppDispatch()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [threatHunterUsers, setThreatHunterUsers] = useState<ThreatHunterUsersResponse | { error: string }>({
    threatHunterUsers: [],
  })

  const { alerts } = useSelector(selectAlerts)
  const openAlerts = [alertOptions[0]]

  useEffect(() => {
    setIsLoading(true)
    fetchThreatHunterUsers()
  }, [])

  useEffect(() => {
    if (threatHunterUsers) {
      di(fetchAlerts(DEFAULT_PAGE, DASH_PAGE_SIZE, openAlerts, threatHunterUsers))
    }
  }, [threatHunterUsers])

  useEffect(() => {
    if (alerts) setIsLoading(false)
  }, [alerts])

  const fetchThreatHunterUsers = async () => {
    const users = await di(getThreatHunterUsers())
    setThreatHunterUsers(users)
  }

  return (
    <div className="bg-white rounded-md p-6 mt-4 h-[390px]">
      <div className="flex justify-between">
        <p className="text-2xl font-bold mb-6">Latest Open Alerts</p>
        <Button
          size={'2'}
          className="cursor-pointer"
          onClick={() => {
            localStorage.setItem('DASHBOARD_PAGE_COOKIE', 'ALERTS')
            dispatch(setCurrentPage('ALERTS'))
          }}
          variant="outline"
        >
          View All Alerts
        </Button>
      </div>

      {!isLoading && alerts && alerts.length > 0 && (
        <>
          <DashboardDataGrid columns={dashAlertColumns()} data={alerts} loading={false} />
        </>
      )}
      {!isLoading && alerts && alerts.length === 0 && (
        <div className="block items-center mt-20">
          <div className="flex items-center justify-center">
            <img src={checkIcon} alt="No open alerts found" className="h-12 w-12" />
          </div>{' '}
          <p className="text-center font-bold">No Open Alerts</p>
          <p className="text-center text-sm">You have no actions for now</p>
        </div>
      )}
      {isLoading && (
        <div className="w-full space-y-4">
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
        </div>
      )}
    </div>
  )
}

export default DashAlerts
