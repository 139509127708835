import { useSelector } from 'react-redux'
import { Button, IconButton } from '@radix-ui/themes'
import { useEffect, useState } from 'react'
import { getScimStatus, selectSettings, updateScimStatus } from '../../reducers/settings'
import { useAppDispatch } from '../../reducers/store'
import toast from 'react-hot-toast'
import { CopyIcon, EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import DisconnectModal from './DisconnectModal'
import ContentSkeleton from '../skeleton/ContentSkeleton'

function ScimSettings() {
  const { scim } = useSelector(selectSettings)
  const [showSCIMModal, setShowSCIMModal] = useState<boolean>(false)
  const [showScimHeader, setShowScimHeader] = useState<boolean>(false)
  const di = useAppDispatch()

  useEffect(() => {
    di(getScimStatus())
  }, [])

  const hasScim = (
    <>
      <label className="text-darkGreyBlue font-semibold text-xs block">SCIM URL</label>
      <div className="h-10 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm mb-4 max-w-[500px]">
        {scim?.scimUrl}
        <div className="flex mt-0.5">
          <IconButton
            type="button"
            size="2"
            color="violet"
            variant="ghost"
            onClick={() => {
              navigator.clipboard.writeText(scim!.scimUrl)
              toast.success('SCIM URL copied to clipboard', { duration: 6000 })
            }}
          >
            <CopyIcon height="16" width="16" />
          </IconButton>
        </div>
      </div>
      <label className="text-darkGreyBlue font-semibold text-xs block">AUTHORIZATION HEADER</label>
      <div className="h-10 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm mb-6 max-w-[500px]">
        {showScimHeader ? scim?.authHeader : '*********************'}
        <div className="flex mt-0.5">
          <IconButton size="2" variant="ghost" className="mr-1" onClick={() => setShowScimHeader(!showScimHeader)}>
            {showScimHeader ? <EyeNoneIcon height="16" width="16" /> : <EyeOpenIcon height="16" width="16" />}
          </IconButton>
          <IconButton
            size="2"
            variant="ghost"
            onClick={() => {
              navigator.clipboard.writeText(scim!.authHeader)
              toast.success('Authorization Header copied to clipboard', { duration: 6000 })
            }}
          >
            <CopyIcon height="16" width="16" />
          </IconButton>
        </div>
      </div>
      <Button className="bg-ErrorRed mt-4 w-[500px] cursor-pointer" onClick={() => setShowSCIMModal(true)}>
        Disconnect SCIM
      </Button>
      <div className="max-w-[500px] mt-8">
        <p className="text-sm mx-auto text-center ">Need help?</p>
        <p className="text-sm text-center">
          Please reference{' '}
          <a
            href="https://docs.iverify.io/scim-provisioning-setup"
            target="_blank"
            className="text-PurpleBlue-10 font-semibold"
          >
            SCIM Setup Documentation
          </a>
        </p>
      </div>
    </>
  )

  const scimNotConfiguredBody = (
    <>
      <div className="italic">Not yet configured</div>
      <Button className="mt-4 bg-PurpleBlue-10 cursor-pointer" onClick={() => di(updateScimStatus(true))}>
        Enable SCIM
      </Button>
    </>
  )

  const scimTabBody = scim?.enabled ? hasScim : scimNotConfiguredBody

  return (
    <>
      <div className="w-full h-full">
        {!scim && <ContentSkeleton />}
        {scim && scimTabBody}
      </div>
      {showSCIMModal && <DisconnectModal setShowModal={setShowSCIMModal} screen={'SCIM'} />}
    </>
  )
}

export default ScimSettings
