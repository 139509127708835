import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import DataGrid from '../data-grid/DataGrid'
import { useSelector } from 'react-redux'
import Popout from '../popout/Popout'
import { Button, IconButton, SegmentedControl, TextField } from '@radix-ui/themes'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import checkIcon from '../../assets/check_circle.svg'
import { selectBlocklist } from '../../reducers/blocklist/blocklist.slice.ts'
import { getBlocklist, getBlocklistLogs } from '../../reducers/blocklist/blocklist.thunk.ts'
import { domainBlocklistColumns } from './DomainBlocklistColumns.tsx'
import AddDomainModal from './AddDomainModal.tsx'
import { BlocklistDomain, BlocklistLog } from '../../types/BlocklistTypes.ts'
import PageSkeleton from '../skeleton/PageSkeleton.tsx'
import { domainBlocklistLogsColumns } from './DomainBlocklistLogsColumns.tsx'

function DomainBlocklistWrapper() {
  const { blocklist, blocklistLogs, noDomains, noLogs } = useSelector(selectBlocklist)
  const di = useAppDispatch()
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [nameFilter, setNameFilter] = useState<string>('')
  const [showPopout, setShowPopout] = useState<boolean>(false)
  const [popoutData, setPopoutData] = useState<BlocklistDomain | BlocklistLog | null>(null)
  const [showAddDomainModal, setShowAddDomainModal] = useState<boolean>(false)
  const [screenToShow, setScreenToShow] = useState<string>('domains')

  useEffect(() => {
    setDataLoading(true)
    di(getBlocklist())
    di(getBlocklistLogs())
  }, [])

  useEffect(() => {
    if (blocklist || noDomains) {
      setDataLoading(false)
    } else if (!blocklist) {
      setDataLoading(true)
    }
  }, [blocklist, noDomains, blocklistLogs, noLogs])

  const searchName = () => {
    setDataLoading(true)
    if (screenToShow === 'domains') {
      di(getBlocklist(nameFilter))
    } else {
      di(getBlocklistLogs(nameFilter))
    }
  }

  const clearNameFilter = () => {
    setDataLoading(true)
    di(getBlocklist())
    di(getBlocklistLogs())
  }

  useEffect(() => {
    if (nameFilter !== '') {
      setNameFilter('')
      clearNameFilter()
    }
  }, [screenToShow])

  return (
    <>
      <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] bg-neutral-3 p-9">
        {dataLoading && (
          <div className="flex justify-center items-center min-h-screen">
            <PageSkeleton />
          </div>
        )}
        {!dataLoading && (
          <div className="flex justify-between">
            <div className="mb-6 flex w-full">
              <SegmentedControl.Root
                defaultValue="domains"
                value={screenToShow}
                className="h-[38px] mr-4 bg-[#00003B0D] text-gray-600"
                onValueChange={(newValue: string) => setScreenToShow(newValue)}
              >
                <SegmentedControl.Item value="domains">Domains</SegmentedControl.Item>
                <SegmentedControl.Item value="requests">Requests</SegmentedControl.Item>
              </SegmentedControl.Root>
              <TextField.Root
                value={nameFilter}
                placeholder="Search by name..."
                className="w-[240px] h-[38px] mr-4"
                onChange={(e) => {
                  setNameFilter(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchName()
                  }
                }}
              >
                <TextField.Slot></TextField.Slot>
                {nameFilter !== '' && (
                  <TextField.Slot>
                    <IconButton
                      size="1"
                      variant="ghost"
                      onClick={() => {
                        setNameFilter('')
                        clearNameFilter()
                      }}
                    >
                      <CrossCircledIcon height="14" width="14" />
                    </IconButton>
                  </TextField.Slot>
                )}
                <TextField.Slot>
                  <IconButton size="1" variant="ghost" onClick={() => searchName()}>
                    <MagnifyingGlassIcon height="14" width="14" />
                  </IconButton>
                </TextField.Slot>
              </TextField.Root>
            </div>
            <div className="flex ml-auto justify-end">
              <Button
                onClick={() => setShowAddDomainModal(true)}
                className="h-[38px] bg-PurpleBlue-10 ml-8 cursor-pointer"
              >
                Add Domain
              </Button>
            </div>
          </div>
        )}

        {screenToShow === 'domains' && blocklist && blocklist.domains?.length > 0 && (
          <>
            <DataGrid
              columns={domainBlocklistColumns(setShowPopout, setPopoutData)}
              data={blocklist.domains}
              loading={dataLoading}
            />
          </>
        )}
        {screenToShow === 'requests' && blocklistLogs && blocklistLogs?.length > 0 && (
          <>
            <DataGrid
              columns={domainBlocklistLogsColumns(setShowPopout, setPopoutData)}
              data={blocklistLogs}
              loading={dataLoading}
            />
          </>
        )}
        {screenToShow === 'domains' && (noDomains || (blocklist && blocklist.domains?.length === 0)) && (
          <div className="block items-center mt-20">
            <div className="flex items-center justify-center">
              <img src={checkIcon} alt="No members found" className="h-12 w-12" />
            </div>
            <p className="text-center font-bold">No Domains Found</p>
            <p className="text-center text-sm">Add blocked domains above or refine your search</p>
          </div>
        )}
        {screenToShow === 'requests' && (noLogs || (blocklistLogs && blocklistLogs?.length === 0)) && (
          <div className="block items-center mt-20">
            <div className="flex items-center justify-center">
              <img src={checkIcon} alt="No members found" className="h-12 w-12" />
            </div>
            <p className="text-center font-bold">No Request Logs Found</p>
            <p className="text-center text-sm">Add blocked domains above or refine your search</p>
          </div>
        )}
      </div>
      {showAddDomainModal && <AddDomainModal setShowModal={setShowAddDomainModal} />}
      {showPopout && (
        <Popout
          setShowPopout={setShowPopout}
          contentScreen={screenToShow === 'domains' ? 'DOMAINS' : 'REQUESTS'}
          popoutData={popoutData}
        />
      )}
    </>
  )
}

export default DomainBlocklistWrapper
