import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { fetchSsoMembersForGroups, getSsoGroups, selectSettings } from '../../reducers/settings'
import { useSelector } from 'react-redux'
import { Button, Checkbox, IconButton, TextField } from '@radix-ui/themes'
import toast from 'react-hot-toast'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { Group } from '../../types/SettingsType'

interface ISsoGroupsPageProps {
  setCurrentPage: (page: string) => void
  setSelectedGroups: (groups: Group[]) => void
  setShowUnenrollModal: (show: boolean) => void
}

function SsoGroupsPage(props: ISsoGroupsPageProps) {
  const { setCurrentPage, setSelectedGroups, setShowUnenrollModal } = props

  const { ssoGroups } = useSelector(selectSettings)
  const di = useAppDispatch()
  const [checkedGroups, setCheckedGroups] = useState<{ [key: string]: boolean }>({})
  const [searchedGroups, setSearchedGroups] = useState<Group[] | undefined>(undefined)
  const [filterGroups, setFilterGroups] = useState<string>('')
  const [validatingGroups, setValidatingGroups] = useState<boolean>(false)
  const [noUsers, setNoUsers] = useState<boolean>(false)

  useEffect(() => {
    di(getSsoGroups())
  }, [])

  useEffect(() => {
    if (ssoGroups && ssoGroups.groups.length !== 0) {
      setSearchedGroups(ssoGroups.groups)
    } else if (ssoGroups && ssoGroups.groups.length === 0) {
      setNoUsers(true)
    }
  }, [ssoGroups])

  useEffect(() => {
    if (filterGroups !== '') {
      const filteredSsoGroups = ssoGroups?.groups.filter((group) =>
        group.name.toLowerCase().startsWith(filterGroups.toLowerCase())
      )
      setSearchedGroups(filteredSsoGroups)
    } else {
      setSearchedGroups(ssoGroups?.groups)
    }
  }, [filterGroups])

  const handleAddGroups = async () => {
    setValidatingGroups(true)
    const selectedGroups = refineSelectedGroups()
    if (selectedGroups.length > 0) {
      setSelectedGroups(selectedGroups)
      const members = await di(fetchSsoMembersForGroups(selectedGroups))
      if (members !== undefined) {
        setCurrentPage('INVITE')
      } else {
        toast.error('Failed to fetch members for the groups', { duration: 6000 })
      }
      setValidatingGroups(false)
    } else {
      toast.error('Please select at least one group', { duration: 6000 })
    }
  }

  const refineSelectedGroups = () => {
    return Object.keys(checkedGroups)
      .filter((key) => checkedGroups[key])
      .map((key) => ssoGroups!.groups.find((group) => group.id === key))
      .filter(Boolean)
      .map((group) => ({ id: group!.id, name: group!.name }))
  }

  const buttonDisabled = () => {
    const allFalse = Object.values(checkedGroups).every((value) => value === false)
    return Object.keys(checkedGroups).length === 0 || allFalse || validatingGroups
  }

  const closeModal = () => {
    localStorage.removeItem('SSO_ONBOARDING_COOKIE')
    setShowUnenrollModal(false)
  }

  return (
    <>
      <div className="flex justify-center">
        <p className="max-w-[500px] text-center">
          Select the user groups you want to have access to the iVerify app. You will be able to confirm data before
          sending invitations.
        </p>
      </div>

      <div className="flex justify-center mt-4">
        <div className="block">
          <TextField.Root
            placeholder="Search Groups"
            className="w-[400px] h-12 p-2 "
            onChange={(e) => {
              setFilterGroups(e.target.value)
            }}
          >
            <TextField.Slot>
              <IconButton size="1" variant="ghost">
                <MagnifyingGlassIcon height="14" width="14" />
              </IconButton>
            </TextField.Slot>
          </TextField.Root>
          <div className="w-[400px] h-[300px] border border-lightGrey rounded-b border-t-0 -mt-1 p-3 bg-tableHeaderGrey">
            <ul>
              {searchedGroups?.map((group) => (
                <li className="mt-1">
                  <Checkbox
                    size="1"
                    className="mt-1.5"
                    checked={checkedGroups[group.name]}
                    onCheckedChange={(e: boolean) => {
                      setCheckedGroups({
                        ...checkedGroups,
                        [group.id]: e,
                      })
                    }}
                  />{' '}
                  {group.name}
                </li>
              ))}
            </ul>
            {noUsers && <p className="text-center mt-4">No groups found</p>}
          </div>
          {!noUsers && (
            <Button
              type="submit"
              className={`w-[400px] h-[36px] rounded mt-[19px] text-white font-semibold text-sm ${validatingGroups ? 'cursor-not-allowed bg-PurpleBlue-7' : 'cursor-pointer'}`}
              value="Add"
              disabled={buttonDisabled()}
              onClick={handleAddGroups}
            >
              <p>{validatingGroups ? 'Fetching Members...' : 'Add Groups'}</p>
            </Button>
          )}

          {noUsers && (
            <Button
              type="submit"
              className="w-[400px] h-[36px] rounded mt-[19px] text-white font-semibold text-sm cursor-pointer"
              value="Add"
              onClick={closeModal}
            >
              <p>Finish Setup</p>
            </Button>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <div className="block">
          <p className="text-sm mx-auto text-center">Need help?</p>
          <p className="text-sm text-center">
            Please reference{' '}
            <a
              href="https://docs.iverify.io/sso-okta-groups"
              target="_blank"
              className="text-PurpleBlue-10 font-semibold"
            >
              Okta Groups Setup Documentation
            </a>
          </p>
          <p className="text-xs text-center italic mt-4 w-[400px]">
            Please note: we will deduplicate users that are within multiple groups.
          </p>
        </div>
      </div>
    </>
  )
}

export default SsoGroupsPage
