import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { Button, Skeleton } from '@radix-ui/themes'
import { setCurrentPage } from '../../reducers/users'
import { dashDeviceColumns } from './DashDeviceColumns'
import { fetchInsecureDevices, selectDevices } from '../../reducers/devices'
import DashboardDataGrid from '../data-grid/DashboardDataGrid'
import checkIcon from '../../assets/check_circle.svg'

function DashInsecureDevices() {
  const di = useAppDispatch()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { insecureDevices } = useSelector(selectDevices)

  useEffect(() => {
    setIsLoading(true)
    di(fetchInsecureDevices())
  }, [])

  useEffect(() => {
    if (insecureDevices) setIsLoading(false)
  }, [insecureDevices])

  return (
    <div className="bg-white rounded-md p-6 mt-8 h-[390px]">
      <div className="flex justify-between">
        <p className="text-2xl font-bold mb-6">Latest Insecure Devices</p>
        <Button
          size={'2'}
          className="cursor-pointer"
          onClick={() => {
            localStorage.setItem('DASHBOARD_PAGE_COOKIE', 'DEVICES')
            dispatch(setCurrentPage('DEVICES'))
          }}
          variant="outline"
        >
          View All Devices
        </Button>
      </div>
      {!isLoading && insecureDevices && insecureDevices.length > 0 && (
        <>
          <DashboardDataGrid columns={dashDeviceColumns()} data={insecureDevices} loading={false} />
        </>
      )}
      {(!isLoading && !insecureDevices) ||
        (insecureDevices?.length === 0 && (
          <div className="block items-center mt-20">
            <div className="flex items-center justify-center">
              <img src={checkIcon} alt="No insecure devices found" className="h-12 w-12" />
            </div>{' '}
            <p className="text-center font-bold">No Insecure Devices</p>
            <p className="text-center text-sm">Device fleet is secure</p>
          </div>
        ))}
      {isLoading && (
        <div className="w-full space-y-4">
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
        </div>
      )}
    </div>
  )
}

export default DashInsecureDevices
