/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { SessionState } from '../../types/UserTypes'

export interface UserState {
  currentPage: string
  loginPage: string
  session: SessionState | undefined
  hasOrgSso: boolean | undefined
}

const initialState: UserState = {
  session: undefined,
  currentPage: 'DASHBOARD',
  loginPage: 'LOGIN',
  hasOrgSso: undefined,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSession(state, action: PayloadAction<any>) {
      state.session = action.payload
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload
    },
    setLoginPage(state, action: PayloadAction<any>) {
      state.loginPage = action.payload
    },
    setHasOrgSso(state, action: PayloadAction<any>) {
      state.hasOrgSso = action.payload
    },
  },
})
export const selectUser = (state: RootState) => state.user

export const { setCurrentPage, setLoginPage, setSession, setHasOrgSso } = userSlice.actions
export default userSlice.reducer
