import { createColumnHelper } from '@tanstack/react-table'
import { AuditLog } from '../../types/SettingsType'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { Button } from '@radix-ui/themes'

export const auditColumns = (getLogDetails: (show: AuditLog) => void) => {
  const columnHelper = createColumnHelper<AuditLog>()

  return [
    columnHelper.accessor('createdAt', {
      header: 'Created Date',
      cell: (info) => convertTimestampToIverifyDate(info.getValue(), true),
    }),
    columnHelper.accessor('createdBy', {
      header: 'Action By',
    }),
    columnHelper.accessor('actionDetails', {
      header: 'Action',
    }),
    {
      header: 'Details',
      cell: (info: { row: { original: AuditLog } }) => {
        const metadata = Object.entries(info.row.original.metadata).length

        if (metadata > 0)
          return (
            <Button
              size={'2'}
              className="cursor-pointer"
              onClick={() => {
                getLogDetails(info.row.original)
              }}
              variant="outline"
            >
              View
            </Button>
          )
        return null
      },
    },
  ]
}
