interface IIntegrationModalProps {
  setShowIntegrationModal: (show: boolean) => void
  revokeKey: () => void
  regenerateKey: () => void
  cancelDeactivateWebhook: () => void
  screenToShow: string
  deactivateWebhook: () => void
  removeConditionalAccess: () => void
  disableConditionalAccess: () => void
  cancelRemoveConditionalAccess: () => void
}

function IntegrationModal(props: IIntegrationModalProps) {
  const {
    setShowIntegrationModal,
    revokeKey,
    cancelDeactivateWebhook,
    regenerateKey,
    screenToShow,
    deactivateWebhook,
    removeConditionalAccess,
    disableConditionalAccess,
    cancelRemoveConditionalAccess,
  } = props

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowIntegrationModal(false)}
    >
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[50%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {screenToShow === 'REGENERATE' && (
          <>
            <h2 className="text-xl font-bold mb-4">Re-generate API Key</h2>
            <p>Are you sure you want to generate a new API token?</p>
            <p className="mb-4">All services using the current API key will be invalidated.</p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 bg-gray-300 rounded-lg"
                onClick={() => {
                  setShowIntegrationModal(false)
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-ErrorRed text-white rounded-lg"
                onClick={() => {
                  setShowIntegrationModal(false)
                  regenerateKey()
                }}
              >
                Yes, Re-generate
              </button>
            </div>
          </>
        )}
        {screenToShow === 'REVOKE' && (
          <>
            <h2 className="text-xl font-bold mb-4">Revoke API Access</h2>
            <p>Are you sure you want to revoke your access to the API?</p>
            <p className="mb-4">All services using the current API key will be revoked.</p>
            <div className="flex justify-end">
              <button className="px-4 py-2 mr-2 bg-gray-300 rounded-lg" onClick={() => setShowIntegrationModal(false)}>
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-ErrorRed text-white rounded-lg"
                onClick={() => {
                  setShowIntegrationModal(false)
                  revokeKey()
                }}
              >
                Yes, Revoke
              </button>
            </div>
          </>
        )}
        {screenToShow === 'WEBHOOK' && (
          <>
            <h2 className="text-xl font-bold mb-4">Deactivate Webhooks</h2>
            <p>Are you sure you want to deactivate webhooks?</p>
            <p className="mb-4">You will not be able to receive webhook notifications.</p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 bg-gray-300 rounded-lg"
                onClick={() => {
                  cancelDeactivateWebhook()
                  setShowIntegrationModal(false)
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-ErrorRed text-white rounded-lg"
                onClick={() => {
                  setShowIntegrationModal(false)
                  deactivateWebhook()
                }}
              >
                Yes, Deactivate
              </button>
            </div>
          </>
        )}
        {screenToShow === 'CONDITIONAL_ACCESS' && (
          <>
            <h2 className="text-xl font-bold mb-4">Remove Conditional Access</h2>
            <p>Are you sure you want to remove conditional access settings?</p>
            <p className="mb-4">All registered conditional access settings will be removed.</p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 bg-gray-300 rounded-lg"
                onClick={() => {
                  setShowIntegrationModal(false)
                  cancelRemoveConditionalAccess()
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-ErrorRed text-white rounded-lg"
                onClick={() => {
                  setShowIntegrationModal(false)
                  removeConditionalAccess()
                }}
              >
                Yes, Remove
              </button>
            </div>
          </>
        )}
        {screenToShow === 'CONDITIONAL_ACCESS_DISABLE' && (
          <>
            <h2 className="text-xl font-bold mb-4">Disable Conditional Access</h2>
            <p>Are you sure you want to disable conditional access settings?</p>
            <p className="mb-4">This will enable blocked devices to restore access.</p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 bg-gray-300 rounded-lg"
                onClick={() => {
                  setShowIntegrationModal(false)
                  cancelRemoveConditionalAccess()
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-ErrorRed text-white rounded-lg"
                onClick={() => {
                  setShowIntegrationModal(false)
                  disableConditionalAccess()
                }}
              >
                Yes, Disable
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default IntegrationModal
