import {
  ANDROID_DEVICE_ROUTE,
  IOS_DEVICE_ROUTE,
  API_URL,
  EMAIL_USERS_ROUTE,
  UNENROLL_DEVICES_ROUTE,
  DEVICES_ROUTE,
  INSECURE_DEVICE_ROUTE,
  DASHBOARD_STATS_ROUTE,
  UPDATE_DEVICE_NAME_ROUTE,
} from '../../strings/api-consts'
import { DashboardStats, Devices, EmailUserCount, InsecureDeviceInformation } from '../../types/DeviceTypes'
import { SelectOptions } from '../../types/DropDownTypes'
import { CustomThunkDispatch } from '../../types/UserTypes'
import { setCurrentPage, setDashboardDeviceStats, setDevices, setInsecureDevices, setTotalPages } from './devices.slice'
import * as Sentry from '@sentry/react'

export const fetchDevices =
  (pageNumber: number, pageSize: number, severityFilter: SelectOptions[], platform: string, searchTerm: string) =>
  async (dispatch: CustomThunkDispatch) => {
    const platformRoute = () => {
      if (platform === 'android') {
        return ANDROID_DEVICE_ROUTE
      } else if (platform === 'ios') {
        return IOS_DEVICE_ROUTE
      } else if (platform === 'unenrolled') {
        return DEVICES_ROUTE
      }
    }

    let url = `${API_URL + platformRoute()}?page=${pageNumber}&pageSize=${pageSize}`

    if (platform === 'unenrolled') {
      url += '&enrolled=false'
    }

    if (searchTerm && searchTerm !== '') {
      const encodedSearchTerm = encodeURIComponent(searchTerm)
      url += `&name=${encodedSearchTerm}`
    }

    if (severityFilter.length > 0 && severityFilter.length !== 3) {
      const severityParams = severityFilter.map((severity) => `&severity=${severity.value}`).join('')
      url += severityParams
    } else if (severityFilter.length === 3) {
      url += ''
    }
    await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((deviceObject: Devices) => {
        dispatch(setCurrentPage(pageNumber - 1))
        dispatch(setTotalPages(deviceObject.totalPages))
        dispatch(setDevices(deviceObject.devices))
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
      })
  }

export const fetchInsecureDevices = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + INSECURE_DEVICE_ROUTE

  await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((deviceObject: InsecureDeviceInformation[]) => {
      dispatch(setInsecureDevices(deviceObject))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
    })
}

export const emailMembers = (codes: string[]) => async () => {
  const url = API_URL + EMAIL_USERS_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(codes),
  })
    .then((res) => res.json())
    .then((count: EmailUserCount) => {
      return count.count
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return undefined
    })
}

export const unenrollDevices = (codes: string[]) => async () => {
  const url = API_URL + UNENROLL_DEVICES_ROUTE

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(codes),
  }).catch((err) => {
    Sentry.captureMessage(err.message)
  })
}

export const getDashStats = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + DASHBOARD_STATS_ROUTE

  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((stats: DashboardStats) => {
      dispatch(setDashboardDeviceStats(stats))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
    })
}

export const updateDeviceName = (accessCode: string, deviceName: string) => async (): Promise<boolean> => {
  const url = API_URL + UPDATE_DEVICE_NAME_ROUTE

  const payload = {
    accessCode: accessCode,
    deviceName: deviceName,
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
    .then((res) => {
      if (!res.ok) {
        return false
      }
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}
